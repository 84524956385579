var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { ModalConfirmForm } from 'app/components/Modal';
import { FormData } from 'app/components/Form';
import { useEffect, useRef } from 'react';
import { ExternalJob } from './ExternalJob';
import { useDispatch, useSelector } from 'react-redux';
import { externalJobsSelector } from 'app/store/selectors/externalJobs';
import { fetchExternalJobs } from 'app/store/reducers/externalJobs';
export const ModalExternalJob = (props) => {
    const { externalJob, onSave, autoFocus } = props, rest = __rest(props, ["externalJob", "onSave", "autoFocus"]);
    const dispatch = useDispatch();
    const externalJobs = useSelector(externalJobsSelector);
    const formDataRef = useRef();
    useEffect(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            yield dispatch(fetchExternalJobs());
        }))();
    }, []);
    return (_jsx(ModalConfirmForm, Object.assign({ draggable: true, title: "External Job", validateLabel: "Save" }, rest, { children: _jsx(FormData, { ref: formDataRef, defaultData: { externalJob: externalJob || {} }, properties: [
                {
                    label: 'External Job',
                    key: 'externalJob',
                    type: 'autocomplete',
                    element: ExternalJob,
                    elementProps: {
                        autoFocus,
                        required: true,
                        externalJobs,
                    },
                },
            ], onSave: (data) => onSave(data.externalJob.value) }) })));
};
