// @flow
import React from 'react'
import moment from 'moment'
import { map, sortBy } from 'lodash'
import {
  dynamicApprovalStatus,
  dynamicApprovalColors,
  dynamicApprovalKeys,
} from 'app/core/constants/dynamicApprovalStatus'
import { optionsDynamicApprovalStatus } from 'app/core/utils/optionsDynamicApprovalStatus'
import getUserNameFromAsset from 'app/core/utils/getUserNameFromAsset'
import TextRead from 'app/components/TextEditor/TextRead.jsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import type { ID, ResourcesList, ProgressionStatus, DynamicApprovalValue } from 'app/core/types'
import type { DefaultCellProps, Column, PrefsProps } from 'app/components/Table/types.js'
import { openModal } from 'app/components/Modal/index.js'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import { AssetHistoryModal } from 'app/containers/AssetHistory/AssetHistoryModal.tsx'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground.js'

import { CellRichText } from '..'
import ModalEditDynamicApproval from './ModalEditDynamicApproval.tsx'
import { pushToActions } from '../pushTo/pushToActions.jsx'
import { pushToColumnActions } from '../pushTo/pushToColumnActions'
import { groupingFns } from './groupingFns.jsx'

import classes from './CellDynamicApproval.module.scss'
import { CellSelect } from '../CellSelect/CellSelect'
import { dynamicApprovalHeaderActions } from './dynamicApprovalHeaderActions'

export type CellDynamicApprovalParams = {|
  ...DefaultCellProps,
  projectId: ?ID,
  isSummary?: boolean,
  extends: {
    progressionStatus: ResourcesList<ProgressionStatus>,
    parentId: ?ID,
    id: string,
    pushType: 'dynamicApprovals' | 'steps',
    targetStep?: ?ID,
  },
|}

export function ReadStatusOnly({ value }: *): React$Node {
  if (!value) return <div />

  return (
    <div className={classes.statusOnlyRead} style={{ backgroundColor: dynamicApprovalColors[value] }}>
      <div style={{ textAlign: 'center' }}>{dynamicApprovalStatus[value]}</div>
    </div>
  )
}

type ReadProps = {|
  isRowExpanded: boolean,
  dynamicApprovalValue: DynamicApprovalValue,
  prefs: PrefsProps,
|}

export function ReadDisplayMin({ isRowExpanded, dynamicApprovalValue, prefs }: ReadProps): React$Node {
  if (!dynamicApprovalValue || !dynamicApprovalValue.id) return <div />

  const { comment, status, authorInst } = dynamicApprovalValue
  const statusReadable = dynamicApprovalStatus[status]
  const authorName = getUserNameFromAsset(authorInst)

  return (
    <div className={classes.readContainer}>
      <div className="flex row noWrap flex1 marginLeft5 fullHeight alignCenter spaceBetween">
        <div>
          {comment ? (
            <Tooltip
              interactive={true}
              style={{ minWidth: 300, maxHeight: '80vh' }}
              placement="left"
              title={
                <div className="flex wrap fullHeight overflowYAuto" style={{ maxHeight: 'calc(80vh - 30px)' }}>
                  <TextRead text={comment} />
                </div>
              }
            >
              <div className={classes.isCollapsedTextIcon}>
                <FontIcon icon="fas-pen" style={{ color: getColorFromBackground('#d3d3d3') }} />
              </div>
            </Tooltip>
          ) : null}
        </div>
        <div className="flex">
          <div
            className={`${classes.status} textNoWrap`}
            style={{
              backgroundColor: dynamicApprovalColors[status],
              color: getColorFromBackground(dynamicApprovalColors[status]),
              padding: '0 10px',
            }}
          >
            {statusReadable}
          </div>
        </div>
        <div className={classes.assignedUser}>{authorName}</div>
      </div>
    </div>
  )
}

export function ReadDisplayAll({ isRowExpanded, dynamicApprovalValue, prefs }: ReadProps): React$Node {
  if (!dynamicApprovalValue || !dynamicApprovalValue.id) return <div />

  const { minLineHeight, maxLineHeight } = prefs
  const { comment, status, statusUpdatedAt, authorInst, updatedAt } = dynamicApprovalValue

  const statusReadable = dynamicApprovalStatus[status]
  const authorName = getUserNameFromAsset(authorInst)
  const updateDate = updatedAt ? moment(new Date(updatedAt)).format('DD/MM/YYYY HH:mm') : null
  const statusDate = statusUpdatedAt ? moment(new Date(statusUpdatedAt)).format('DD/MM/YYYY HH:mm') : null

  return (
    <div className={classes.readContainer} style={{ maxHeight: (isRowExpanded ? maxLineHeight : minLineHeight) - 2 }}>
      <div className={`flex row noWrap ${classes.top}`}>
        <div className={classes.assignedUser}>{authorName}</div>
        <div className="flex1 noPointerEvents">
          {!isRowExpanded && comment ? (
            <Tooltip
              interactive={true}
              style={{ minWidth: 300, maxHeight: '80vh' }}
              placement="left"
              title={
                <div
                  className="flex wrap fullHeight overflowYAuto"
                  style={{ maxHeight: 'calc(80vh - 30px)' }}
                  onDoubleClick={(e) => e.nativeEvent.stopImmediatePropagation()}
                >
                  <TextRead text={comment} />
                </div>
              }
            >
              <div className={classes.isCollapsedTextIcon}>
                <FontIcon icon="fas-pen" style={{ color: getColorFromBackground('#d3d3d3') }} />
              </div>
            </Tooltip>
          ) : null}
        </div>
        <span className={classes.date}>
          {updateDate ? (
            <Tooltip title={`Last dynamic approval update: ${updateDate}`}>
              <span>
                <FontIcon icon="fas-edit" className="marginRight5 fontSize10" style={{ color: 'rgba(0,0,0,0.5)' }} />
                <span>{updateDate}</span>
              </span>
            </Tooltip>
          ) : null}
        </span>
      </div>
      {(isRowExpanded || minLineHeight >= 70) && statusDate ? (
        <Tooltip title={`Last status update: ${statusDate}`}>
          <div className={`${classes.date} flex row center alignCenter flex1 fontSize12`}>
            <FontIcon
              icon="fas-traffic-light"
              className="marginRight5 fontSize10"
              style={{ color: 'rgba(0,0,0,0.5)' }}
            />
            <span>{statusDate}</span>
          </div>
        </Tooltip>
      ) : null}
      <div
        className={classes.status}
        style={{
          backgroundColor: dynamicApprovalColors[status],
          color: getColorFromBackground(dynamicApprovalColors[status]),
        }}
      >
        {statusReadable}
      </div>
      {isRowExpanded ? (
        <TextRead
          text={comment || ''}
          className={classes.comment}
          containerProps={{
            style: { maxHeight: `${maxLineHeight - 70}px`, overflowY: 'auto' },
          }}
          preventImageReflow={true}
        />
      ) : null}
    </div>
  )
}

function ReadSummary({ isRowExpanded, dynamicApprovalValue, prefs }: ReadProps) {
  const { minLineHeight, maxLineHeight } = prefs
  const { status, statusUpdatedAt, authorInst, updatedAt, statusSummary } = dynamicApprovalValue

  const statusSummaryComment = (
    <div className="marginTop10 flex column alignStart">
      <div className="bold fontSize16 marginBottom10">Summary</div>
      <div className="grid col2 paddingLeft10">
        {sortBy(statusSummary, ['name']).map((statusSum) => [
          <div key={statusSum.name} className="flex end">
            <div>{statusSum.name}:</div>
          </div>,
          <div key={`${statusSum.name}-${statusSum.status}`} className="flex alignCenter">
            <div
              className="flex textNoWrap"
              style={{
                backgroundColor: statusSum.status ? dynamicApprovalColors[statusSum.status] : 'transparant',
                color: statusSum.status ? getColorFromBackground(dynamicApprovalColors[statusSum.status]) : undefined,
                borderRadius: '3px',
                fontWeight: 'bold',
                padding: '2px 5px',
              }}
            >
              {statusSum.status ? dynamicApprovalStatus[statusSum.status] : 'No status'}
            </div>
          </div>,
        ])}
      </div>
    </div>
  )

  const statusReadable = dynamicApprovalStatus[status]
  const authorName = getUserNameFromAsset(authorInst)
  const updateDate = updatedAt ? moment(new Date(updatedAt)).format('DD/MM/YYYY HH:mm') : null
  const statusDate = statusUpdatedAt ? moment(new Date(statusUpdatedAt)).format('DD/MM/YYYY HH:mm') : null

  return (
    <div className={classes.readContainer} style={{ maxHeight: (isRowExpanded ? maxLineHeight : minLineHeight) - 2 }}>
      {!isRowExpanded && minLineHeight < 70 ? null : (
        <div className={`flex row wrap ${classes.top}`}>
          <div className={classes.assignedUser}>{authorName}</div>
          <div className={classes.summaryTitle}>Summary</div>
          <span className={classes.date}>
            {updateDate ? (
              <Tooltip title={`Last dynamic approval update: ${updateDate}`}>
                <span>
                  <FontIcon icon="fas-edit" className="marginRight5 fontSize10" style={{ color: 'rgba(0,0,0,0.5)' }} />
                  <span>{updateDate}</span>
                </span>
              </Tooltip>
            ) : null}
          </span>
          <div />
        </div>
      )}

      <Tooltip title={statusSummaryComment} placement="left">
        <div className={classes.status}>
          <div
            className="flex row fullWidth spaceBetween wrap"
            style={{
              padding: '0 10px',
              height: 'calc(100% - 10px)',
              backgroundColor: dynamicApprovalColors[status],
              color: getColorFromBackground(dynamicApprovalColors[status]),
            }}
          >
            <span>{statusReadable}</span>
            {statusDate ? (
              <Tooltip title={`Last status update: ${statusDate}`}>
                <span className="fontSize11" style={{ color: 'rgba(0,0,0,0.5)', fontWeight: 'none' }}>
                  <FontIcon icon="fas-traffic-light" className="marginRight5 fontSize10" />
                  <span>{statusDate}</span>
                </span>
              </Tooltip>
            ) : null}
          </div>
          <div className="flex row noWrap fullWidth relative" style={{ bottom: 0 }}>
            {sortBy(statusSummary, ['name']).map((da, index: number): React$Element<any> => (
              <div
                key={String(index)}
                style={{
                  backgroundColor: da.status ? dynamicApprovalColors[da.status] : null,
                  flex: 1,
                  height: 10,
                  borderTop: '2px solid #D3D3D3',
                  borderRight: index < Object.keys(statusSummary || {}).length - 1 ? '3px solid #D3D3D3' : undefined,
                }}
              />
            ))}
          </div>
        </div>
      </Tooltip>
      {isRowExpanded ? statusSummaryComment : null}
    </div>
  )
}

const Read = (props: ReadProps) => {
  const { isRowExpanded, prefs, dynamicApprovalValue } = props
  const { minLineHeight, maxLineHeight } = prefs

  if (dynamicApprovalValue?.statusSummary) return ReadSummary(props)
  if (isRowExpanded && maxLineHeight < 150) return ReadDisplayAll({ ...props, isRowExpanded: false })
  if (!isRowExpanded && minLineHeight < 49) return ReadDisplayMin(props)
  return ReadDisplayAll(props)
}

export const CellDynamicApproval = ({ actions, isSummary, projectId, ...data }: CellDynamicApprovalParams): Column => {
  return {
    cellType: 'CellDynamicApproval',
    Cell: (instance) => {
      const { cell, prefs } = instance
      const { value: dynamicApprovalValue, getCellProps } = cell
      const { isRowExpanded } = getCellProps()

      return <Read dynamicApprovalValue={dynamicApprovalValue} isRowExpanded={isRowExpanded} prefs={prefs} />
    },
    EditModal: (instance) => {
      const {
        cell,
        state: { selectedCells },
        cellPositionLabel,
      } = instance
      const { value: dynamicApprovalValue, getCellProps } = cell
      const { edition } = getCellProps()
      const { endEdit, save } = edition

      return (
        <ModalEditDynamicApproval
          multipleCellsEdit={selectedCells ? Object.keys(selectedCells).length > 1 : false}
          dynamicApprovalValue={dynamicApprovalValue}
          onChange={save}
          onRequestClose={endEdit}
          exponentTitle={cellPositionLabel}
        />
      )
    },
    headerActions: (instance, column) => {
      if (isSummary) return []
      return [...pushToColumnActions(column.id, instance, column), ...dynamicApprovalHeaderActions(instance, column)]
    },
    actions: (instance, cell) => {
      if (isSummary) return []

      const { state } = instance
      const { selectedCells } = state
      const { getCellProps, column, value: dynamicApproval } = cell
      const { edition } = getCellProps()
      const { save } = edition

      const resolvedActions = (typeof actions === 'function' ? actions(instance, cell) : actions) || []

      if (!dynamicApproval.statusSummary) {
        resolvedActions.push(
          { separator: true },
          {
            label: 'Status',
            editAction: true,
            items: map(optionsDynamicApprovalStatus, (opt, index) => ({
              onClick: () =>
                save({
                  id: cell.value?.id,
                  asset: cell.value?.asset,
                  dynamicApproval: cell.value?.dynamicApproval,
                  status: opt.value,
                }),
              editAction: true,
              label: opt.label,
              color: opt.backgroundColor,
              hotKeys: [dynamicApprovalKeys[opt.value]],
              rightLabel: dynamicApprovalKeys[opt.value],
            })),
          },
        )
      }

      if (dynamicApproval?.id && Object.keys(selectedCells || {}).length < 2) {
        resolvedActions.push(
          { separator: true },
          {
            label: 'Show history',
            editAction: false,
            onClick: () => {
              openModal(
                <AssetHistoryModal
                  tableId="dynAppHistory"
                  projectId={projectId}
                  columns={[
                    {
                      Header: 'Dynamic Approval',
                      id: 'dynApp',
                      columns: [
                        CellRichText({
                          id: 'richComment',
                          Header: 'Comment',
                          accessor: 'actionObject.comment',
                          noFilterOption: true,
                          readOnly: true,
                          actions: () => [],
                          width: undefined,
                          minWidth: 150,
                        }),
                        CellSelect({
                          id: 'status',
                          Header: 'Status',
                          accessor: 'actionObject.status',
                          width: 100,
                          noFilterOption: true,
                          readOnly: true,
                          actions: () => [],
                          options: () => optionsDynamicApprovalStatus,
                        }),
                      ],
                    },
                  ]}
                  resourceType="dynamicApprovalValues"
                  requestName="history"
                  assetId={dynamicApproval.id}
                  title="Dynamic approval history"
                />,
              )
            },
          },
        )
      }

      const pustToActions = pushToActions(column.id, instance, cell)

      if (pustToActions.length && !dynamicApproval.statusSummary) {
        resolvedActions.push({ separator: true }, ...pustToActions)
      }

      return resolvedActions
    },
    onPastEvent: (value, instance, cell) => {
      const { save } = cell.getCellProps().edition
      return save({ ...cell.value, comment: value || '' })
    },
    width: 350,
    groupingFns,
    ...data,
  }
}
