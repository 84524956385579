/** @flow */
import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import Project from 'app/pages/Project/Project.jsx'
import Projects from 'app/pages/Project/ProjectsHomePage/Projects.tsx'
import Assets from 'app/pages/Project/Assets/AssetList/Assets.jsx'
import { Tasks } from 'app/pages/Project/Tasks/Tasks.jsx'
import { Breakdown } from 'app/pages/Project/Breakdown/Breakdown.jsx'
import { TablePostboardModels } from 'app/pages/Project/PostBoardModel/TablePostBoardModels.tsx'
import { TablePostBoardShots } from 'app/pages/Project/PostBoardShot/TablePostBoardShots.jsx'
import Groups from 'app/pages/Project/Settings/Groups/Groups.jsx'
import { Steps } from 'app/pages/Project/Settings/Steps/Steps.jsx'
import { SettingsFollowUp } from 'app/pages/Project/Settings/FollowUp/SettingsFollowUp.jsx'
import { FollowUp } from 'app/pages/Project/ProductionManagement/FollowUp/FollowUp.tsx'
import { ExternalFollowUp } from 'app/pages/Project/ProductionManagement/ExternalFollowUp/ExternalFollowUp.jsx'
import { ProjectWiki } from 'app/pages/Project/ProjectWiki/ProjectWiki.jsx'
import { TableFiles } from 'app/pages/Project/Assets/Files/TableFiles.jsx'
import Library from 'app/pages/Project/Assets/Library/Library.tsx'
import { Dashboard } from 'app/pages/Project/Dashboard/Dashboard.jsx'
import { Teams } from 'app/pages/Project/Dashboard/Teams/Teams.jsx'
import { Flags } from 'app/pages/Project/Settings/Flags/Flags'

function ProjectChildren() {
  return (
    <Project>
      <Switch>
        <Route
          exact={true}
          path="/projects/:projectId"
          render={({ match }) => {
            return <Redirect to={`${match.url}/assets`} />
          }}
        />
        <Route exact={true} path="/projects/:projectId/assets" component={Assets} />
        <Route exact={true} path="/projects/:projectId/library" component={Library} />
        <Route exact={true} path="/projects/:projectId/dashboard/:type?/:episodeId?" component={Dashboard} />
        <Route exact={true} path="/projects/:projectId/teams" component={Teams} />
        <Route exact={true} path="/projects/:projectId/files/:assetId?" component={TableFiles} />
        <Route exact={true} path="/projects/:projectId/tasks" component={Tasks} />
        <Route exact={true} path="/projects/:projectId/breakdown/:breakdownItemId?" component={Breakdown} />
        <Route exact={true} path="/projects/:projectId/postboard-models/:episodeId?" component={TablePostboardModels} />
        <Route exact={true} path="/projects/:projectId/postboard-shots/:episodeId?" component={TablePostBoardShots} />
        <Route path="/projects/:projectId/wiki/:assetId?/:articleId?/:articleVersion?" component={ProjectWiki} />
        <Route path="/projects/:projectId/follow-up/:assetId?/:trackingSchemaId?" component={FollowUp} />
        <Route
          path="/projects/:projectId/external-follow-up/:assetId?/:trackingSchemaId?"
          component={ExternalFollowUp}
        />
        <Route path="/projects/:projectId/settings/follow-up/:trackingSchemaId?" component={SettingsFollowUp} />
        <Route path="/projects/:projectId/settings/groups/users-by-group/:groupId?" component={Groups} />
        <Route path="/projects/:projectId/settings/steps" component={Steps} />
        <Route path="/projects/:projectId/settings/flags" component={Flags} />
      </Switch>
    </Project>
  )
}

export default function (): React$Element<any> {
  return (
    <Switch>
      <Route exact={true} path="/projects" component={Projects} />
      <Route path="/projects/:projectId" component={ProjectChildren} />
    </Switch>
  )
}
