var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { map } from 'lodash';
import LoaderSmall from 'app/components/LoaderSmall/LoaderSmall.jsx';
import classes from './Usage.module.scss';
export function UsageShots(props) {
    const { episode, fetchShotsUsage, shots } = props;
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        ;
        (() => __awaiter(this, void 0, void 0, function* () {
            if (!shots) {
                setLoader(true);
                yield fetchShotsUsage(episode);
            }
            setLoader(false);
        }))();
    }, []);
    return loader ? (_jsx("div", { className: "flex center alignCenter padding30 fullWidth", children: _jsx(LoaderSmall, { style: { width: 30, height: 30 } }) })) : (_jsx("div", { className: "grid col9", children: map(shots, (shot) => {
            return (_jsx(Link, { className: classes.shotLink, to: `/projects/${shot.shot.project || ''}/assets?assetDetails=/${shot.shot.id}/breakdownShot&assetDetails-rightPanel=false`, children: shot.label }, shot.shot.id));
        }) }));
}
