// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-pages-Project-ProjectsHomePage-___Projects-module__container {
  height: 100%;
  overflow-y: auto;
}

  .app-pages-Project-ProjectsHomePage-___Projects-module__container .app-pages-Project-ProjectsHomePage-___Projects-module__projects {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
  }
`, "",{"version":3,"sources":["webpack://./app/pages/Project/ProjectsHomePage/Projects.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAUlB;;EARE;IACE,aAAa;IACb,6BAA6B;IAC7B,aAAa;IACb,eAAe;IACf,UAAU;IACV,cAAc;EAChB","sourcesContent":[".container {\n  height: 100%;\n  overflow-y: auto;\n\n  .projects {\n    display: flex;\n    justify-content: space-around;\n    padding: 20px;\n    flex-wrap: wrap;\n    width: 80%;\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app-pages-Project-ProjectsHomePage-___Projects-module__container`,
	"projects": `app-pages-Project-ProjectsHomePage-___Projects-module__projects`
};
export default ___CSS_LOADER_EXPORT___;
