import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useState } from 'react';
import { filter, sortBy } from 'lodash';
import Widget from 'app/components/Widget/Widget.jsx';
import { Title } from 'app/components/Texts/Title/Title.jsx';
import { AutocompleteSingle } from 'app/components/Form';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectView } from './ProjectView/ProjectView';
import { fetchAllProjects } from 'app/store/reducers/projects';
import { projectsReducerSelector } from 'app/store/selectors/projects';
export const ProjectsList = () => {
    const dispatch = useDispatch();
    const { data: projects } = useSelector(projectsReducerSelector);
    const [results, setResults] = useState([]);
    const [search, setSearch] = useState('');
    useEffect(() => {
        dispatch(fetchAllProjects());
    }, []);
    function searchInProjects(search) {
        setSearch(search);
        setResults(filter(projects, (project) => project.name.includes(search)));
        return Promise.resolve();
    }
    return (_jsxs(Widget, { style: { padding: 10, width: '100%' }, children: [_jsxs("div", { className: "flex row noWrap fullWidth spaceBetween", children: [_jsx(Title, { size: "2", className: "marginBottom10", children: "Projects list" }), _jsx("div", { children: _jsx(AutocompleteSingle, { onSearch: searchInProjects, onSearchDelay: 0, lengthForSearch: 0, style: { width: 200 }, placeholder: "Search in projects", searchable: true }) })] }), _jsx("div", { style: { height: 'calc(100% - 52px)', overflowY: 'auto' }, className: "fullWidth", children: sortBy(search ? results : projects, ['name']).map((project, index) => (_jsx(ProjectView, { project: project }, project.id))) })] }));
};
