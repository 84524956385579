import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useState, useCallback, useEffect } from 'react';
import { map } from 'lodash';
import classes from './EmblaCarousel.module.scss';
import useEmblaCarousel from 'embla-carousel-react';
import { DotButton, PrevButton, NextButton } from './EmblaCarouselButtons';
import Cards from './Cards';
import { CardSkeleton } from './CardSkeleton';
const OrganizationChartCarousel = (props) => {
    const { options, people, offices, projectPlans, newArrived, isLoading } = props;
    const [emblaRef, emblaApi] = useEmblaCarousel(options ? options : {});
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [scrollSnaps, setScrollSnaps] = useState([]);
    const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
    const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
    const scrollTo = useCallback((index) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);
    useEffect(() => {
        if (emblaApi) {
            const onSelect = () => {
                setSelectedIndex(emblaApi.selectedScrollSnap());
                setPrevBtnEnabled(emblaApi.canScrollPrev());
                setNextBtnEnabled(emblaApi.canScrollNext());
            };
            setScrollSnaps(emblaApi.scrollSnapList());
            emblaApi.on('select', onSelect);
            onSelect();
        }
        return () => emblaApi && emblaApi.destroy();
    }, [emblaApi]);
    useEffect(() => {
        if (emblaApi) {
            emblaApi.reInit({});
            setScrollSnaps(emblaApi.scrollSnapList());
            setNextBtnEnabled(emblaApi.canScrollNext());
        }
    }, [emblaApi]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.textNewArrivals, children: "New arrivals :" }), isLoading ? (_jsx("div", { className: classes.skeletonContainer, children: _jsx(CardSkeleton, { cards: 3 }) })) : newArrived.length ? (_jsxs(_Fragment, { children: [_jsx("div", { className: `${classes.embla__viewport} ${Object.keys(people).length <= 6 ? classes.embla__viewport__small : ''}`, ref: emblaRef, children: _jsx("div", { className: classes.embla__container, children: map(people, (people) => (_jsx("div", { className: classes.embla__slide, children: _jsx(Cards, { people: people, offices: offices, projectPlans: projectPlans }) }, people.id))) }) }), _jsx("div", { className: classes.embla__dots, children: scrollSnaps.length > 0 &&
                            scrollSnaps.map((snap, index) => (_jsx(DotButton, { selected: index === selectedIndex, onClick: () => scrollTo(index) }, index))) }), _jsx(PrevButton, { onClick: scrollPrev, enabled: prevBtnEnabled }), _jsx(NextButton, { onClick: scrollNext, enabled: nextBtnEnabled })] })) : (_jsx("div", { className: classes.fallbackMessage, children: "Nobody arrived recently." }))] }));
};
export default OrganizationChartCarousel;
