// @flow
import React, { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { isBoolean, find, forEach, map, padEnd, reduce, head, filter, flatten, sortBy, values } from 'lodash'
import { Table } from 'app/components/Table'
import { useDispatch, useSelector } from 'react-redux'
import { setLockedFilters } from 'app/store/reducers/lockedFilters.ts'
import type {
  Asset,
  ID,
  TrackingSchema,
  TrackingSchemaColumn,
  Task,
  Take,
  Step,
  ResourcesList,
  PostBoardLink,
  AssetLink,
  FlagRelation,
  CalculatedField,
  DynamicApproval,
  ProgressionStatus,
  Attribute,
  Activity,
  Flag,
} from 'app/core/types'
import type { Column, ToolbarProps, ColumnHeader, ExportExcelFormat, CellInstance } from 'app/components/Table/types.js'
import { createUpdatingFlagsPromises } from 'app/components/Form/Flags/utils'
import { getUserSettings, setUserSettings } from 'app/libs/helpers/userSettings'
import {
  CellLink,
  CellTask,
  CellThumbnail,
  CellText,
  CellDuration,
  CellProgression,
  CellRichText,
  CellFlags,
  CellSelect,
  CellRating,
  CellCheckbox,
  CellPriority,
  CellMedias,
  CellAssetsList,
  CellTimecode,
  CellAggregatedAttribute,
  CellDynamicApproval,
  CellAssetsSelect,
  CellNotesFollowUp,
  CellStatus,
  CellAggregatedDynamicApproval,
  CellSmartDate,
  ColumnTimeline,
} from 'app/components/Table/Cells'
import {
  CellStatsDefault,
  CellStatsStatus,
  CellStatsAssignedUsers,
  CellStatsStep,
  CellStatsAttributes,
  CellStatsFlags,
  CellStatsDynamicApprovals,
  CellStatsCalculatedFields,
  CellStatsAssetLinks,
} from 'app/components/Table/Cells/StatsCells/index.js'
import { router } from 'app/containers/Assets/AssetDetail/router'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import { permission } from 'app/containers/Permissions'
import { getResources } from 'app/store/selectors'
import { assetIcons } from 'app/components/Icons/assetsIcons.js'
import { viewableValue } from 'app/components/Form/FormData/getInput.tsx'
import { DurationRead } from 'app/components/Duration/Duration.tsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { assetsTypes } from 'app/core/constants/index.js'
import { regex, dateToLocaleStringFull } from 'app/libs/helpers/index.js'
import store from 'app/store/index.ts'
import { accessorTakeMedias, saveTakeMedias } from 'app/components/Table/Cells/CellMedias/utils.js'
import { getEpisodes } from 'app/store/selectors/getEpisodes.js'
import resources from 'app/store/resources'
import history from 'app/main/routerHistory'
import { TimelineCellComponent } from 'app/components/Table/Cells/ColumnTimeline/CellComponents/TimelineCellComponent'
import { TimelineDrawerComponentActivities } from 'app/components/Table/Cells/ColumnTimeline/CellComponents/Drawers'
import { SendEvent } from 'app/core/utils/SendEvent.js'

import { Filters } from './Filters'
import { getLastTakeFromTask, getLastTakeFromAsset, getCalculatedFieldType } from './utils'
import { createRecursiveRequestIfNoPostboardLink } from '../../PostBoardShot/utils'

export type FollowUpPermissions = $Exact<{
  tasks: boolean,
  takes: boolean,
  assets: boolean,
  attributes: boolean,
  steps: boolean,
  dynApp: boolean,
  notes: boolean,
  assetLinks: boolean,
  timeline: {|
    read: boolean,
    startEndUpdate: boolean,
    earliestLatestUpdate: boolean,
  |},
}>

export type Props = {|
  asset: ?Asset,
  trackingSchema: ?TrackingSchema,
  extendToolbar?: $ElementType<ToolbarProps, 'extendToolbar'>,
  projectId: ID,
  resetTable: boolean,
  permissions?: FollowUpPermissions,
|}

type CalculatedFieldColumn = TrackingSchemaColumn<CalculatedField>
type AttributesColumn = TrackingSchemaColumn<Attribute>
type LastTakeColumn = TrackingSchemaColumn<$Exact<{ attr: string, step: ID }>>
type AssetLinksColumn = TrackingSchemaColumn<$Exact<{ category: string, id: ID }>>
type AssetsColumn = TrackingSchemaColumn<$Exact<{ attr: string }>>
type TasksColumn = TrackingSchemaColumn<$Exact<{ attr: string, step: ID }>>
type DynamicApprovalColumn = TrackingSchemaColumn<$Exact<{ id: ID, targetStep: ID, assetType: string, project: ID }>>
type AggregatedColumn = TrackingSchemaColumn<
  $Exact<{ aggregateType: string, calculatedFieldType: string, category: string }>,
>
type StepColumn = TrackingSchemaColumn<$Exact<{ id: ID }>>

export function TableFollowUp(props: Props): React$Node {
  const { asset, trackingSchema, extendToolbar, projectId, resetTable, permissions: propsPermissions } = props
  const dispatch = useDispatch()
  const optionId = `lockFilters-${projectId}`
  const lockFilters = useSelector((state) => state.lockedFilters.projectsLockedFilters[optionId])

  const [allFilters, setAllFilters] = useState([])

  useEffect(() => {
    dispatch(setLockedFilters({ projectId: optionId, isLocked: getUserSettings(optionId) }))
  }, [])

  const includedResources = [
    'parentInst',
    'assetFlagsInst.flagInst',
    'tasksInst',
    'tasksInst.activitiesInst',
    'tasksInst.assignedUserInst',
    'tasksInst.suggestedUserInst',
    'tasksInst.statusInst',
    'tasksInst.takesInst',
    'tasksInst.stepInst',
    'tasksInst.takesInst.statusInst',
    'tasksInst.takesInst.takeFlagsInst',
    'tasksInst.takesInst.takeFlagsInst.flagInst',
    'tasksInst.takesInst.takeRefMediasInst',
    'tasksInst.takesInst.takeRefMediasInst.mediaInst',
    'tasksInst.takesInst.takeValidationMediasInst',
    'tasksInst.takesInst.takeValidationMediasInst.mediaInst',
    'tasksInst.taskViewersInst.assetInst',
    'thumbnailInst',
    'dynamicApprovalValuesInst',
    'dynamicApprovalValuesInst.authorInst',
    'assetLinksInst.to_assetInst',
    'postBoardLinksInst',
    'postBoardLinksInst.notesInst',
  ]

  const tableId: ?string = useMemo(
    () => (trackingSchema ? `table-follow-up-${trackingSchema.id}` : null),
    [trackingSchema],
  )

  const [paginatedList, setPaginatedList] = useState<string | void | null>('')
  const [showGantt, setShowGantt] = useState<boolean>(false)
  const [ganttColorsType, setGanttColorsType] = useState<'step' | 'status'>('step')

  const prefs = useRef()

  const progressionStatus: ResourcesList<ProgressionStatus> = useMemo(
    () => getResources(undefined, 'progressionStatus'),
    [],
  )
  const assets = getResources(undefined, `assets.paginatedLists.${paginatedList || ''}`, undefined, includedResources)
  const attributes: ResourcesList<Attribute> = useMemo(() => getResources(undefined, 'attributes'), [])
  const steps: ResourcesList<Step> = useMemo(() => getResources(undefined, 'steps'), [])
  const dynamicApprovals: ResourcesList<DynamicApproval> = useMemo(
    () => getResources(undefined, 'dynamicApprovals', { project: projectId }),
    [],
  )
  const episodes: ResourcesList<Asset> = useMemo(() => getEpisodes(), [])

  const { restrictedActions = [] } = trackingSchema || {}

  const trackingSchemas = useSelector((state) => state.trackingSchemas.resources) || []
  const filtersFromTrackingSchemas = []

  // WIP to clean
  useEffect(() => {
    values(trackingSchemas)?.forEach((ts) => {
      ts.schema.forEach((schema) => {
        if (schema?.type) {
          filtersFromTrackingSchemas.push(schema?.items)
        }
      })
    })
    const filters = new Filters({
      columns: filtersFromTrackingSchemas.flat().filter((_) => _),
      attributes,
      // assetType: trackingSchema.assetType,
    }).getFilters()
    setAllFilters(filters)
  }, [trackingSchemas])

  const permissions: FollowUpPermissions = useMemo(
    () =>
      propsPermissions || {
        assets: permission(['projet_follow-up__update', 'projet_follow-up_update only columns_assets'], 'or'),
        attributes: permission(['projet_follow-up__update', 'projet_follow-up_update only columns_attributes'], 'or'),
        tasks: permission(['projet_follow-up__update', 'projet_follow-up_update only columns_tasks'], 'or'),
        takes: permission(['projet_follow-up__update', 'projet_follow-up_update only columns_takes'], 'or'),
        steps: permission(['projet_follow-up__update', 'projet_follow-up_update only columns_steps'], 'or'),
        dynApp: permission(
          ['projet_follow-up__update', 'projet_follow-up_update only columns_dynamic approvals'],
          'or',
        ),
        notes: permission(['projet_follow-up__update', 'projet_follow-up_update only columns_notes'], 'or'),
        assetLinks: permission(['projet_follow-up__update', 'projet_follow-up_update only columns_asset links'], 'or'),
        timeline: {
          read: permission(
            [
              'projet_follow-up_timeline_read',
              'projet_follow-up_timeline_update start/end dates',
              'projet_follow-up_timeline_update earliest/latest dates',
            ],
            'or',
          ),
          startEndUpdate: permission(['projet_follow-up_timeline_update start/end dates']),
          earliestLatestUpdate: permission(['projet_follow-up_timeline_update earliest/latest dates']),
        },
      },
    [propsPermissions],
  )

  function fitToActivities(task: $Exact<Task>, instance: CellInstance) {
    const secondesToDays = (sec) => sec / 60 / 60 / 7.8

    const activities: Array<Activity> = sortBy(task.activitiesInst, ['date'])

    const activitiesTot: number = activities.reduce((acc, act) => acc + act.duration, 0)
    const estim =
      Math.max(
        reduce(task.takesInst, (acc, take) => take.estimLength + acc, 0), // all takes estim
        task.realEstimLength || 0, // suppervisor
      ) ||
      task.quoteEstimLength ||
      0
    const activitiesPerc = (activitiesTot / 100) * 95
    let activitiesSum = 0
    const startDate = activities?.[0]?.date ? moment(activities?.[0]?.date) : moment(task.startDate)
    let endDate

    if ([1].includes(task.statusInst?.statusType)) {
      const estimDays = secondesToDays(estim)
      endDate = moment(startDate).add(estimDays, 'days')
    }
    // DONE   WAITING FOR APPROVAL   CLOSED
    else if ([2, 3].includes(task.statusInst?.statusType) || estim === 0) {
      for (let i = 0; i < activities.length; i += 1) {
        const act = activities[i]
        activitiesSum += act.duration
        if (activitiesSum >= activitiesPerc) {
          endDate = moment(act.date, 'YYYY-MM-DD')
          break
        }
      }
    }
    // UNAPPROVED   STAND BY   WORK IN PROGRESS  TO DO
    else if ([0, 4, 5].includes(task.statusInst?.statusType)) {
      if (estim) {
        if (activitiesTot <= estim) {
          let diff = secondesToDays(estim - activitiesTot)
          endDate = moment()
          while (diff > 0) {
            if ([6, 0].includes(endDate.day())) {
              endDate.add(1, 'day')
              continue
            }
            endDate.add(1, 'day')
            diff -= 1
          }
        } else if (activitiesTot > estim) {
          let diff = secondesToDays(estim * 0.1)
          endDate = moment()
          while (diff > 0) {
            if ([6, 0].includes(endDate.day())) {
              endDate.add(1, 'day')
              continue
            }
            endDate.add(1, 'day')
            diff -= 1
          }
        }
      }
    }

    if (startDate && endDate && startDate?.isSameOrBefore(endDate)) {
      return resources.tasks
        ?.update({ id: task.id, startDate: startDate.toISOString(), endDate: endDate?.toISOString() })
        .then(() => {
          const { cell, updateCells } = instance
          updateCells({ [cell.id]: cell })
        })
    }
    return Promise.resolve()
  }

  function renderLastTakeCell(column: LastTakeColumn) {
    const {
      description,
      color,
      columnName,
      name,
      hiddenable,
      isVisible = true,
      hidden,
      readOnly,
      fixable,
      fixed,
      attr,
      step,
      timestamp,
    } = column

    const cellContent = {
      Header: columnName || name,
      description,
      headerColor: color,
      hiddenable: isBoolean(hiddenable) ? hiddenable : true,
      hiddenByDefault: !isVisible,
      hidden,
      fixable: isBoolean(fixable) ? fixable : true,
      fixed,
      sortingKey: `lastTake_${step}_${attr}`,
      readOnly: !permissions.takes || (isBoolean(readOnly) ? readOnly : false),
      draggable: !trackingSchema?.settings?.lockColumnOrder,
      id: String(timestamp),
      accessor: (item: Asset) => {
        if (!item) return undefined
        const lastTake = getLastTakeFromAsset(item, column)
        if (!lastTake) return undefined
        return lastTake[attr] || null
      },
      actions: (tableInstance, cell) => {
        const lastTake = getLastTakeFromAsset(cell.row.original, column)
        return lastTake ? ['edit', 'copy', 'past'] : []
      },
      save: {
        resource: 'takes',
        formatData: (item, value, cell, instance, type) => {
          const lastTake = getLastTakeFromAsset(item, column)
          if (!lastTake) return null

          return {
            id: lastTake && lastTake.id,
            [attr]: value,
          }
        },
      },
      Placeholder: (instance) => {
        const lastTake = getLastTakeFromAsset(instance?.cell?.row.original, column)
        if (!lastTake) return <div className="fullHeight flex center alignCenter lightgrey fontSize11">No task</div>
        return undefined
      },
    }

    switch (attr) {
      case 'estimLength':
        return CellDuration({
          ...cellContent,
          Stats: (instance) => {
            const { stats } = instance
            if (!stats) return null
            const stepStats = { default: stats[`step_${step}`] }
            return (
              <CellStatsDefault
                instance={{ ...instance, stats: stepStats }}
                resource="takes"
                fields={['estimLength']}
              />
            )
          },
          actions: (tableInstance, cell) => {
            const lastTake = getLastTakeFromAsset(cell.row.original, column)
            return lastTake ? ['edit', 'copy', 'past'] : []
          },
        })

      case 'number':
        return CellText({
          ...cellContent,
          inputProps: { type: 'number' },
          noFilterOption: true,
          actions: (tableInstance, cell) => (regex.exist(cell.value) ? ['copy'] : []),
          Stats: (instance) => {
            const { stats } = instance
            if (!stats) return null
            const stepStats = { default: stats[`step_${step}`] }
            return (
              <CellStatsDefault
                instance={{ ...instance, stats: stepStats }}
                resource="takes"
                fields={['number__counts']}
                updateFields={(entry) => ({
                  'Take number': entry.fTakes__number,
                  count: entry.count,
                })}
              />
            )
          },
        })

      case 'takeFlagsInst':
        return CellFlags({
          ...cellContent,
          foreignKey: 'take',
          category: 'take',
          sortingKey: undefined,
          actions: (tableInstance, cell) => {
            const lastTake = getLastTakeFromAsset(cell.row.original, column)
            return lastTake ? ['edit', 'delete', 'copy', 'past'] : []
          },
          save: {
            resource: 'takes',
            formatData: (item, value, cell, instance, type) => {
              const lastTake = getLastTakeFromAsset(item, column)
              if (!lastTake) return null

              if (type === 'delete') value = []

              const newFlags = map(value, ({ flagInst }) => ({ take: lastTake.id, flag: flagInst.id }))
              return createUpdatingFlagsPromises(newFlags, map(lastTake.takeFlagsInst), 'takeFlags', type)
            },
          },
          getResourceID: (cell) => cell.row.original?.id,
        })

      case 'status':
        return CellStatus({
          ...cellContent,
          progressionStatus,
          Stats: (instance) => {
            const { stats } = instance
            if (!stats) return null
            const stepStats = { default: stats[`step_${step}`] }
            return (
              <CellStatsStatus
                instance={{ ...instance, stats: stepStats }}
                progressionStatus={progressionStatus}
                columnName={columnName}
              />
            )
          },
          save: {
            resource: 'takes',
            formatData: (item, value, cell, instance, type) => {
              const lastTake = getLastTakeFromAsset(item, column)
              if (!lastTake) return null

              return {
                id: lastTake.id,
                status: value,
              }
            },
          },
        })

      case 'takeValidationMediasInst':
        return CellMedias({
          ...cellContent,
          accessor: (item: Asset) => {
            if (!item) return undefined
            const lastTake = getLastTakeFromAsset(item, column)
            if (!lastTake) return undefined
            const { takeValidationMediasInst } = lastTake

            return accessorTakeMedias(takeValidationMediasInst)
          },
          sortingKey: undefined,
          allowPinMedia: true,
          allowValidateMedia: true,
          actions: () => ['edit', 'delete'],
          save: {
            resource: 'takeValidationMedias',
            formatData: (item, value, cell, instance, type) => {
              if (!item) return null
              const lastTake = getLastTakeFromAsset(item, column)
              if (!lastTake) return null

              return saveTakeMedias(
                value,
                'takeValidationMedias',
                lastTake.id,
                lastTake.takeValidationMediasInst,
                type,
                cell.value,
              )
            },
          },
        })

      case 'takeRefMediasInst':
        return CellMedias({
          ...cellContent,
          accessor: (item: Asset) => {
            if (!item) return undefined
            const lastTake = getLastTakeFromAsset(item, column)
            if (!lastTake) return undefined
            const { takeRefMediasInst } = lastTake

            return accessorTakeMedias(takeRefMediasInst)
          },
          sortingKey: undefined,
          allowPinMedia: true,
          allowValidateMedia: true,
          actions: () => ['edit', 'delete'],
          save: {
            resource: 'takeRefMedias',
            formatData: (item, value, cell, instance, type) => {
              if (!item) return null
              const lastTake = getLastTakeFromAsset(item, column)
              if (!lastTake) return null

              return saveTakeMedias(value, 'takeRefMedias', lastTake.id, lastTake.takeRefMediasInst, type, cell.value)
            },
          },
        })

      case 'comment':
        return CellRichText({
          ...cellContent,
          actions: (tableInstance, cell) => {
            const lastTake = getLastTakeFromAsset(cell.row.original, column)
            return lastTake ? ['edit', 'copy', 'past', 'delete'] : []
          },
          width: 200,
          accessor: (item: Asset) => {
            if (!item) return undefined
            const lastTake = getLastTakeFromAsset(item, column)
            if (!lastTake) return undefined
            return lastTake[attr] || ''
          },
          showHistoryParams: (instance, cell) => {
            const {
              row: { original },
            } = cell
            const lastTake = getLastTakeFromAsset(original, column)
            if (!lastTake) return null

            return {
              assetId: lastTake.id,
              projectId,
              resourceType: 'takes',
              title: 'Comment',
              requestName: 'history',
              accessor: 'comment',
            }
          },
        })

      case 'validationComment':
        return CellRichText({
          ...cellContent,
          actions: (tableInstance, cell) => {
            const lastTake = getLastTakeFromAsset(cell.row.original, column)
            return lastTake ? ['edit', 'copy', 'past', 'delete'] : []
          },
          width: 200,
          accessor: (item: Asset) => {
            if (!item) return undefined

            const lastTake = getLastTakeFromAsset(item, column)
            if (!lastTake) return undefined
            return lastTake[attr] || ''
          },
          showHistoryParams: (instance, cell) => {
            const {
              row: { original },
            } = cell
            const take = getLastTakeFromAsset(original, column)
            if (!take) return null

            return {
              assetId: take.id,
              projectId,
              resourceType: 'takes',
              title: 'Validation comment',
              requestName: 'history',
              accessor: 'validationComment',
            }
          },
        })

      case 'name':
        return CellText({
          ...cellContent,
          Stats: (instance) => {
            const { stats } = instance
            if (!stats) return null
            const stepStats = { default: stats[`step_${step}`] }
            return (
              <CellStatsDefault
                instance={{ ...instance, stats: stepStats }}
                resource="takes"
                updateFields={(entry) => {
                  if (typeof entry === 'object' && entry.fTakes__number) {
                    return {
                      'Take number': entry.fTakes__number,
                      count: entry.count,
                    }
                  }
                  return entry
                }}
              />
            )
          },
        })

      default:
        throw new Error(`unknow lastTake attr ${attr}`)
    }
  }

  function renderNotesCell(column: Object) {
    const {
      step,
      description,
      columnName,
      name,
      timestamp,
      hiddenable,
      isVisible = true,
      hidden,
      fixable,
      fixed,
    } = column

    const readOnly = !permissions.notes || (isBoolean(column.readOnly) ? column.readOnly : false)
    if (step.assetType === 'sh') {
      const findNote = (postBoardLinksInst: ResourcesList<PostBoardLink>) => {
        const postBoardLink = map(postBoardLinksInst)[0]
        const note = find(postBoardLink?.notesInst, ({ step: noteStep }) => {
          if (noteStep === step.id || (noteStep === null && step.id === 'general')) return true
          return false
        })
        return note
      }

      return CellRichText({
        Header: columnName || name,
        description,
        hiddenable: isBoolean(hiddenable) ? hiddenable : true,
        hiddenByDefault: !isVisible,
        hidden,
        fixable: isBoolean(fixable) ? fixable : true,
        fixed,
        readOnly,
        actions: (instance, cell) => ['edit', 'past', 'copy', 'delete'],
        actionsIfNotValidContent: (instance, cell) => ['edit', 'past'],
        checkValidContent: (cell) => {
          const { postBoardLinksInst } = cell.row.original
          const note = findNote(postBoardLinksInst)
          if (!note) {
            return <div className="lightgrey fullWidth fullHeight flex center alignCenter">No note</div>
          }
          return undefined
        },
        accessor: (item) => {
          if (!item) return ''
          const { postBoardLinksInst } = item
          return findNote(postBoardLinksInst)?.text || ''
        },
        showHistoryParams: (instance, cell) => {
          const {
            row: { original },
          } = cell
          const note = findNote(original.postBoardLinksInst)
          if (!note) return null

          return {
            assetId: note.id,
            projectId,
            resourceType: 'postBoardNotes',
            title: `${step.name} note`,
            requestName: 'history',
            accessor: 'text',
          }
        },
        id: String(timestamp),
        width: 300,
        extends: {
          step,
        },
        save: {
          resource: 'postBoardNotes',
          formatData: (item, value, cell, instance, type) => {
            const postBoardLink = head(map(item.postBoardLinksInst))
            if (!postBoardLink) {
              return createRecursiveRequestIfNoPostboardLink(
                item,
                undefined,
                value,
                step.id === 'general' ? null : step,
              )
            }
            const { notesInst, id } = postBoardLink

            const note = find(notesInst, ({ step: noteStep }) => {
              if (noteStep === step.id || (noteStep === null && step.id === 'general')) return true
              return false
            })

            if (!note) return { link: id, text: value, step: step.id === 'general' ? null : step.id }
            return { id: note.id, text: value }
          },
        },
      })
    }

    return CellNotesFollowUp({
      Header: columnName || name,
      description,
      hiddenable: isBoolean(hiddenable) ? hiddenable : true,
      hiddenByDefault: !isVisible,
      hidden,
      fixable: isBoolean(fixable) ? fixable : true,
      fixed,
      readOnly,
      id: String(timestamp),
      step,
      getAsset: (cell) => cell.row.original,
      episodes,
      accessor: 'postBoardLinksInst',
      save: {
        resource: 'postBoardNotes',
        formatData: (item, value, cell, instance, type) => value,
      },
    })
  }

  function renderAssetLinks(column: AssetLinksColumn) {
    type Item = { ...Asset, assetLinksInst: ResourcesList<AssetLink> }
    const {
      hiddenable,
      isVisible = true,
      hidden,
      fixed,
      fixable,
      readOnly,
      category,
      description,
      columnName,
      name,
      timestamp,
      length,
    } = column

    if (length) {
      return CellText({
        Header: columnName || name,
        description,
        id: String(timestamp),
        hiddenable: isBoolean(hiddenable) ? hiddenable : true,
        hiddenByDefault: !isVisible,
        hidden,
        fixable: isBoolean(fixable) ? fixable : true,
        fixed,
        readOnly: !permissions.assetLinks || (isBoolean(readOnly) ? readOnly : false),
        draggable: !trackingSchema?.settings?.lockColumnOrder,
        accessor: (item: Item) => {
          if (!item) return []
          return filter(item.assetLinksInst, (link) => link.to_assetInst.attributes.category === category).length
        },
        width: 30,
      })
    }

    return CellAssetsList({
      Header: columnName || name,
      description,
      id: String(timestamp),
      disableClickableAssets: restrictedActions.includes('assetSheet'),
      hiddenable: isBoolean(hiddenable) ? hiddenable : true,
      hiddenByDefault: !isVisible,
      hidden,
      fixable: isBoolean(fixable) ? fixable : true,
      fixed,
      readOnly: !permissions.assetLinks || (isBoolean(readOnly) ? readOnly : false),
      draggable: !trackingSchema?.settings?.lockColumnOrder,
      searchFilterLabel: 'Linked assets',
      filterLabel: 'Linked assets',
      accessor: (item: Item) => {
        if (!item) return []
        return filter(item.assetLinksInst, (link) => link.to_assetInst.attributes.category === category)
      },
      width: 200,
      category,
      actions: () =>
        ['edit', 'copy', 'past', ...(permission(['projet_breakdown_links_delete']) ? ['delete'] : [])].filter((_) => _),
      onOpenItem: (asset) => {
        router.goTo('index', { assetId: asset.id }, { rightPanel: true })
      },
      save: {
        resource: 'assetLinks',
        formatData: (item, value, cell, instance, type) => {
          if (type === 'merge') {
            if (map(value).length === 0) return null

            return map(
              value.filter((rel) => rel.to_assetInst.attributes.category === cell.column.extends?.category),
              (link) => ({
                // ...link,
                from_asset: item.id,
                datas: undefined,
                to_asset: link.to_assetInst.id,
                linkType: column?.id,
                id: undefined,
                to_assetInst: value?.to_assetInst,
              }),
            )
          }

          if (type === 'past') {
            if (map(value).length === 0) return null
            return {
              type: 'rel',
              resource: 'assetLinks',
              toCreate: value
                .filter((rel) => rel.to_assetInst.attributes.category === cell.column.extends?.category)
                .map((link) => ({
                  // ...link,
                  from_asset: item.id,
                  datas: undefined,
                  to_asset: link.to_assetInst.id,
                  linkType: column?.id,
                  id: undefined,
                  to_assetInst: value?.to_assetInst,
                })),
              toDelete: cell.value.map((link) => link.id),
            }
          }

          if (type === 'delete') return map(cell.value, ({ id }) => id)

          const { toCreate, toDelete } = value

          const toCreateByColumn = toCreate.filter((rel) => {
            const relCategory = rel.assetInst?.attributes?.category
            const { category } = cell.column.extends || {}
            if (!relCategory || !category) return false
            return relCategory === category && rel.from_asset === item.id
          })

          return {
            type: 'rel',
            resource: 'assetLinks',
            toCreate: toCreateByColumn,
            toDelete,
          }
        },
      },
      Stats: (instance) => <CellStatsAssetLinks instance={instance} category={category} />,
    })
  }

  function renderAssetCell(column: AssetsColumn) {
    const {
      readOnly,
      isVisible = true,
      hidden,
      columnName,
      name,
      fixable,
      hiddenable,
      fixed,
      attr,
      type,
      color,
      timestamp,
    } = column

    const cellContent = {
      Header: columnName || name,
      headerColor: color,
      hiddenable: isBoolean(hiddenable) ? hiddenable : true,
      hiddenByDefault: !isVisible,
      hidden,
      fixable: isBoolean(fixable) ? fixable : true,
      fixed,
      readOnly: !permissions.assets || (isBoolean(readOnly) ? readOnly : false),
      draggable: !trackingSchema?.settings?.lockColumnOrder,
      id: String(timestamp),
      accessor: attr,
      actions: () => ['edit', 'copy'],
      save: {
        type,
        formatData: (item, value, cell, instance, type) => {
          return {
            id: item.id,
            [attr]: value,
          }
        },
      },
    }

    switch (attr) {
      case 'assetFlagsInst':
        return CellFlags({
          ...cellContent,
          accessor: 'assetFlagsInst',
          category: (cell) => cell.row.original?.assetType,
          actions: () => ['edit', 'copy', 'past', 'delete'],
          foreignKey: 'asset',
          getResourceID: (cell) => cell.row.original?.id,
          save: {
            resource: 'assets',
            formatData: (item, value, cell, instance, type) => {
              if (type === 'delete') value = []
              const newFlags = map(value, ({ flagInst }) => ({ asset: item.id, flag: flagInst.id }))
              return createUpdatingFlagsPromises(newFlags, map(item.assetFlagsInst), 'assetFlags', type)
            },
          },
          Stats: (instance) => {
            return <CellStatsFlags instance={instance} columnName={columnName} />
          },
        })
      case 'thumbnailInst':
        return CellThumbnail({
          ...cellContent,
          save: {
            resource: 'assets',
            formatData: (item, value, cell, instance, type) => {
              return {
                id: item.id,
                thumbnail: value.id,
              }
            },
          },
        })
      case 'assetType':
        return CellText({
          ...cellContent,
          actions: () => [],
          RenderRead: (cell, value) => (
            <div className="flex row alignCenter">
              <FontIcon icon={assetIcons(value)} className="marginRight5" />
              {assetsTypes[value]}
            </div>
          ),
          save: {
            resource: 'assets',
          },
        })
      case 'parentInst':
        return CellAssetsSelect({
          ...cellContent,
          actions: () => [],
          placeholder: 'Asset parent',
          readOnly: true,
          sortingKey: 'parent__name',
          save: {
            resource: 'assets',
          },
        })

      default:
        throw new Error(`unknow asset attr ${column.attr}`)
    }
  }

  function renderTasksCell(column: TasksColumn) {
    const {
      readOnly,
      isVisible = true,
      hidden,
      description,
      columnName,
      name,
      fixable,
      hiddenable,
      fixed,
      color,
      step,
      attr,
      timestamp,
    } = column

    const cellContent = {
      id: String(timestamp),
      Header: columnName || name,
      headerColor: color,
      description,
      hiddenable: isBoolean(hiddenable) ? hiddenable : true,
      hiddenByDefault: !isVisible,
      hidden,
      fixable: isBoolean(fixable) ? fixable : true,
      sortingKey: `step_${step}_${attr}`,
      fixed,
      readOnly: !permissions.tasks || (isBoolean(readOnly) ? readOnly : false),
      draggable: !trackingSchema?.settings?.lockColumnOrder,
      accessor: (item: Asset) => {
        if (!item) return undefined
        const task = find(item.tasksInst, (task) => task.step === step)
        if (!task) return undefined
        if (!task[attr]) return null
        return task[attr] || null
      },
      actions: (tableInstance, cell) => (cell.row.value ? ['edit', 'copy'] : ['copy']),
      save: {
        resource: 'tasks',
        formatData: (item, value, cell, instance, type) => {
          const task = find(item.tasksInst, (task) => task.step === step)
          if (!task) return null
          return {
            id: task.id,
            [attr]: value,
          }
        },
      },
      Placeholder: (instance) => {
        const task = find(instance.cell.row.original.tasksInst, (task) => task.step === step)
        if (!task) return <div className="fullHeight flex center alignCenter lightgrey fontSize11">No task</div>
        return undefined
      },
    }

    switch (attr) {
      case 'priority':
        return CellPriority({
          ...cellContent,
          actions: (instance, cell) => {
            if (cell.value !== undefined) return ['edit', 'copy', 'past', 'delete']
            return []
          },
          save: {
            resource: 'tasks',
            formatData: (item, value, cell, instance, type) => {
              const task = find(item.tasksInst, (task) => task.step === step)
              if (!task) return null

              if (type === 'delete') value = 0

              return {
                id: task.id,
                [attr]: value || 0,
              }
            },
          },
        })

      case 'startDate':
      case 'endDate':
      case 'earliestStartDate':
      case 'latestEndDate': {
        return CellText({
          ...cellContent,
          inputProps: {
            type: 'date',
          },
          accessor: (item: Asset) => {
            if (!item) return undefined
            const task = find(item.tasksInst, (task) => task.step === step)
            if (!task) return undefined
            if (!task[attr]) return null
            return moment(task[attr]).format('YYYY-MM-DD')
          },
          Stats: (instance) => {
            const { stats } = instance
            if (!stats) return null
            const stepStats = { default: stats[`step_${step}`] }
            return <CellStatsDefault instance={{ ...instance, stats: stepStats }} resource="tasks" fields={[attr]} />
          },
          actions: (instance, cell) => {
            if (cell.value !== undefined) return ['edit', 'copy', 'past']
            return []
          },
          save: {
            resource: 'tasks',
            formatData: (item, value, cell, instance, type) => {
              const task = find(item.tasksInst, (task) => task.step === step)
              if (!task) return null
              return {
                id: task.id,
                // $FlowFixMe
                [attr]: moment(value, 'YYYY-MM-DD').toISOString(),
              }
            },
          },
        })
      }

      case 'status':
        return CellStatus({
          ...cellContent,
          Stats: (instance) => {
            const { stats } = instance
            if (!stats) return null
            const stepStats = { default: stats[`step_${step}`] }
            return (
              <CellStatsStatus
                instance={{ ...instance, stats: stepStats }}
                progressionStatus={progressionStatus}
                columnName={columnName}
              />
            )
          },
          save: {
            resource: 'takes',
            formatData: (item, value, cell, instance, type) => {
              const lastTake = getLastTakeFromAsset(item, column)
              if (!lastTake) return null

              return {
                id: lastTake && lastTake.id,
                [attr]: value,
              }
            },
          },
          actions: (instance, cell) => {
            if (cell.value !== undefined) return ['edit', 'copy', 'past']
            return []
          },
          progressionStatus,
        })

      case 'assignedUser':
        return CellAssetsSelect({
          ...cellContent,
          Stats: (instance) => {
            const { stats } = instance
            if (!stats) return null
            const stepStats = { default: stats[`step_${step}`] }
            return <CellStatsAssignedUsers instance={{ ...instance, stats: stepStats }} columnName={columnName} />
          },
          accessor: (item: Asset) => {
            if (!item) return undefined
            const task = find(item.tasksInst, (task) => task.step === step)
            if (!task) return undefined
            if (!task.assignedUser) return null
            return task.assignedUserInst
          },
          adornment: (cell) => {
            const task: ?Task = find(cell.row.original?.tasksInst, (task) => task.step === step)
            const suggestedUserName = task && !task.assignedUser && task.suggestedUserInst?.name
            return suggestedUserName ? (
              <span className="absolute grey fontSize12" style={{ zIndex: 1, top: 0, left: 5 }}>
                <FontIcon icon="fas-scroll" className="marginRight5 fontSize10" />
                <span
                  style={{
                    borderRadius: 3,
                    backgroundColor: '#008AE6',
                    color: '#fff',
                    padding: '0px 5px',
                    marginRight: 5,
                  }}
                >
                  {suggestedUserName}
                </span>
                is suggested
              </span>
            ) : null
          },
          actions: (instance, cell) => {
            const task = find(cell.row.original?.tasksInst, (task) => task.step === step)
            const shouldHaveSuggestMenu = Boolean(task && !task.assignedUser && task.suggestedUserInst?.id)

            if (!task) return []
            if (task && !shouldHaveSuggestMenu) return ['edit', 'delete', 'copy', 'past']

            const {
              state: { selectedCells },
            } = instance.getLastestInstance()

            const autoAssigneSuggestion = {
              label:
                Object.keys(selectedCells || {}).length > 1
                  ? 'Assign suggestions'
                  : `Assign ${task.suggestedUserInst?.name || 'suggestion'}`,
              rightLabel: 'Ctrl + A',
              editAction: true,
              onClick: (event) => {
                event.preventDefault()
                event.stopPropagation()

                const { getLastestSelection } = instance.getLastestInstance()

                forEach(getLastestSelection().selectedCells, (_cell, cellId) => {
                  const _task = find(_cell.row.original?.tasksInst, (_task) => _task.step === step)
                  const suggestedUserId = _task && !_task.assignedUser && _task.suggestedUserInst?.id

                  if (suggestedUserId) _cell.getCellProps().edition?.saveTargetOnly({ id: suggestedUserId })
                })
              },
              hotKeys: ['ctrl+a', 'command+a'],
            }

            return [autoAssigneSuggestion, { separator: true }, 'edit', 'delete', 'copy', 'past']
          },
          save: {
            resource: 'tasks',
            formatData: (item, value, cell, instance, type) => {
              const task = find(item.tasksInst, (task) => task.step === step)
              if (!task) return null

              if (type === 'past') {
                return {
                  id: task.id,
                  assignedUser: value?.id || null,
                }
              }

              return {
                id: task.id,
                [attr]: value?.id || null,
              }
            },
          },
          sortingKey: `step_${step}_${attr}__name`,
          assetTypes: ['us'],
          placeholder: 'Assign an user',
        })

      case 'taskViewersInst':
        return CellAssetsSelect({
          ...cellContent,
          accessor: (item: Asset) => {
            if (!item) return undefined
            const task = find(item.tasksInst, (task) => task.step === step)
            return task?.taskViewersInst
          },
          placeholder: 'Select viewers',
          multiple: true,
          assetTypes: ['us'],
          foreignKey: 'task',
          getResourceId: (cell) => find(cell.row.original?.tasksInst, (task) => task.step === step) || '',
          save: {
            resource: 'taskViewers',
            formatData: (item, value, cell, instance, type) => {
              const task = find(item.tasksInst, (task) => task.step === step)
              if (!task) return null

              const request = []
              const alreadyCreated = []

              value.forEach((taskViewer) => {
                if (taskViewer.id) {
                  alreadyCreated.push(taskViewer.id)
                  return
                }
                request.push({ ...taskViewer, assetInst: undefined })
              })

              if (task && task.taskViewersInst) {
                forEach(task.taskViewersInst, (rel) => {
                  if (alreadyCreated.indexOf(rel.id) === -1) {
                    request.push(rel.id)
                  }
                })
              }

              return request
            },
          },
        })
      case 'activitiesInst':
        return CellDuration({
          ...cellContent,
          actions: () => [],
          noFilterOption: true,
          sortingKey: undefined,
          Stats: (instance) => {
            const { stats } = instance
            if (!stats) return null
            const stepStats = { default: stats[`step_${step}`] }
            return <CellStatsDefault instance={{ ...instance, stats: stepStats }} resource="activities" />
          },
          accessor: (item: Asset) => {
            if (!item) return undefined
            const task = find(item.tasksInst, (task) => task.step === step)
            if (!task) return undefined
            if (!task.activitiesInst) return null
            return reduce(task.activitiesInst, (a, b) => a + b.duration, 0)
          },
        })
      case 'quoteEstimLength':
        return CellDuration({
          ...cellContent,
          actions: () => [],
          accessor: (item: Asset) => {
            if (!item) return undefined
            const task = find(item.tasksInst, (task) => task.step === step)
            if (!task) return undefined
            return task.quoteEstimLength
          },
          Stats: (instance) => {
            const { stats } = instance
            if (!stats) return null
            const stepStats = { default: stats[`step_${step}`] }
            return (
              <CellStatsDefault
                instance={{ ...instance, stats: stepStats }}
                resource="tasks"
                fields={['quoteEstimLength']}
              />
            )
          },
        })
      case 'realEstimLength':
        return CellDuration({
          ...cellContent,
          actions: (instance, cell) => (regex.exist(cell.value) ? ['edit', 'copy', 'past'] : []),
          accessor: (item: Asset) => {
            if (!item) return undefined
            const task = find(item.tasksInst, (task) => task.step === step)
            if (!task) return undefined
            return task.realEstimLength
          },
          Stats: (instance) => {
            const { stats } = instance
            if (!stats) return null
            const stepStats = { default: stats[`step_${step}`] }
            return (
              <CellStatsDefault
                instance={{ ...instance, stats: stepStats }}
                resource="tasks"
                fields={['realEstimLength']}
              />
            )
          },
        })
      case 'estimLength':
        return CellDuration({
          ...cellContent,
          actions: () => [],
          Stats: (instance) => {
            const { stats } = instance
            if (!stats) return null
            const stepStats = { default: stats[`step_${step}`] }
            return (
              <CellStatsDefault
                instance={{ ...instance, stats: stepStats }}
                resource="tasks"
                fields={['estimLength']}
              />
            )
          },
          sortingKey: undefined,
          accessor: (item: Asset) => {
            if (!item) return undefined
            const task: ?Task = find(item.tasksInst, (task) => task.step === step)
            if (!task) return undefined
            if (!task.takesInst) return null
            return reduce(task.takesInst, (a: number, b: Take) => a + b.estimLength, 0)
          },
        })
      case 'difference':
        return CellProgression({
          ...cellContent,
          noFilterOption: true,
          sortingKey: undefined,
          readMask: (value) => DurationRead({ value }),
          accessor: (item: Asset) => {
            if (!item) return undefined
            const task = find(item.tasksInst, (task) => task.step === step)
            if (!task) return undefined
            if (!task.activitiesInst) return null
            const totalDuration = reduce(task.activitiesInst, (a, b) => a + b.duration, 0)
            const estimLength = reduce(task.takesInst, (a, b) => a + b.estimLength, 0)

            return {
              max: estimLength,
              value: totalDuration,
            }
          },
          actions: () => [],
        })

      case 'name':
        return CellText({
          ...cellContent,
          Stats: (instance) => {
            const { stats } = instance
            if (!stats) return null
            const stepStats = { default: stats[`step_${step}`] }
            return (
              <CellStatsDefault
                instance={{ ...instance, stats: stepStats }}
                resource="tasks"
                updateFields={(entry) => {
                  if (typeof entry === 'object') {
                    if (entry.step_id) {
                      return {
                        Step: steps[entry.step_id]?.name || '?',
                        count: entry.count,
                      }
                    }
                    if (entry.asset__parent__name) {
                      return {
                        'Parent name': entry.asset__parent__name,
                        'Asset type': entry.asset__parent__assetType,
                        count: entry.count,
                      }
                    }
                  }
                  return entry
                }}
              />
            )
          },
          actions: (tableInstance, cell) => (cell.row.value ? ['edit', 'copy', 'past'] : []),
        })
      default:
        return null
      // throw new Error(`unknow task attr ${attr}`)
    }
  }

  function renderAttributeCell(column: AttributesColumn) {
    const {
      id,
      readOnly,
      isVisible = true,
      hidden,
      description,
      columnName,
      name,
      hiddenable,
      fixable,
      fixed,
      color,
      timestamp,
    } = column
    const attributeId: string = id
    const attribute = { ...attributes[attributeId] }

    if (column.editorType !== attribute.editorType) {
      attribute.editorType = column.editorType
      attribute.editorParams = column.editorParams
    }

    const cellContent = {
      id: String(timestamp),
      Header: columnName || name,
      headerColor: color,
      description,
      hiddenable: isBoolean(hiddenable) ? hiddenable : true,
      hiddenByDefault: !isVisible,
      hidden,
      fixable: isBoolean(fixable) ? fixable : true,
      fixed,
      readOnly: !permissions.attributes || (isBoolean(readOnly) ? readOnly : false),
      draggable: !trackingSchema?.settings?.lockColumnOrder,
      sortingKey: `attributes__${attribute.name}`,
      menuActions: ['duration', 'integer'].includes(attribute.attrType),
      Stats: (instance) => {
        let formatValue: (value: any) => React$Node
        let formatLabel: (value: any) => React$Node
        let radialLabel: (values: Object) => React$Node

        if (['rating', 'priority', 'text'].includes(attribute.editorType)) {
          formatLabel = (val) => viewableValue(attribute.attrType, val, attribute.editorType) || 'null'
          if (attribute.editorType === 'rating')
            radialLabel = (values) => padEnd('★'.repeat(Number(values.label)), 5, '☆')
        } else {
          // $FlowFixMe
          formatValue = (val) => {
            if (attribute.editorType === 'smartdate') return val
            return viewableValue(attribute.attrType, val, attribute.editorType, attribute.editorParams) || 'null'
          }
        }

        return (
          <CellStatsAttributes
            instance={instance}
            name={attribute.name}
            formatValue={formatValue}
            formatLabel={formatLabel}
            radialLabel={radialLabel}
            showModal={['smartdate'].includes(attribute.editorType)}
          />
        )
      },
      accessor: `attributes.${attribute.name}`,
      actions: () => ['edit', 'delete', 'copy', 'past'],
      save: {
        resource: 'assets',
        formatData: (item: Asset, value, cell, instance, type) => {
          if (type === 'delete') {
            return {
              id: item.id,
              attributes: { ...item.attributes, [attribute.name]: null },
            }
          }

          return {
            id: item.id,
            attributes: { ...item.attributes, [attribute.name]: value },
          }
        },
      },
    }

    const createMinMaxValidator = ({ min, max }, errorLabel, format = (data: any) => data) => {
      const validators: Object = {}
      if (min)
        validators.min = (value) => (format(value) < format(min) ? `${errorLabel} must be grather than ${min}` : false)
      if (max)
        validators.max = (value) => (format(value) > format(max) ? `${errorLabel} must be lower than ${max}` : false)
      return validators
    }

    if (attribute.editorType === 'choice') {
      const options = () => {
        const label = (value) => {
          const str = viewableValue(attribute.attrType, value, attribute.editorType)
          return str ? String(str) : ''
        }
        return (
          attribute?.editorParams?.choice?.map((value) => ({
            value,
            label: label(value),
          })) || []
        )
      }

      return CellSelect({
        ...cellContent,
        readMask: (value) => {
          if (attribute.attrType === 'datetime') return dateToLocaleStringFull(value)
          return value
        },
        accessor: (item: Asset) => {
          if (!item) return ''
          const { attributes } = item
          if (!attributes) return ''

          if (attribute.attrType === 'integer' && attributes[attribute.name]) {
            return String(attributes[attribute.name])
          }

          if (attribute.attrType === 'datetime' && attributes[attribute.name]) {
            const datetime = new Date(attributes[attribute.name])
            return datetime.toISOString()
          }

          if (attribute.attrType === 'time' && attributes[attribute.name]) {
            return moment(attributes[attribute.name], 'HH:MM:SS').format('HH:MM')
          }

          return attributes[attribute.name]
        },
        save: {
          resource: 'assets',
          formatData: (item: Asset, value, cell, instance, type) => {
            let returnedValue = value

            if (type === 'delete') return { id: item.id, attributes: { [attribute.name]: null } }
            if (attribute.attrType === 'datetime') returnedValue = new Date(value)
            return { id: item.id, attributes: { ...item.attributes, [attribute.name]: returnedValue } }
          },
        },
        options,
      })
    }

    switch (attribute.attrType) {
      case 'bool': {
        return CellCheckbox({ ...cellContent })
      }
      case 'char': {
        const { colored } = attribute.editorParams

        return CellText({
          ...cellContent,
          colored,
          noReadStyle: colored,
          actions: () => ['edit', 'delete', 'copy', 'past'],
          width: 200,
        })
      }
      case 'date': {
        const validators: Object = {}
        const { min, max } = attribute.editorParams
        if (min) {
          validators.min = (value: Date) => {
            return new Date(value) < new Date(min)
              ? `date must be grather than ${new Date(min).toLocaleDateString()}`
              : false
          }
        }
        if (max) {
          validators.max = (value: Date) => {
            return new Date(value) > new Date(max)
              ? `date must be lower than ${new Date(max).toLocaleDateString()}`
              : false
          }
        }

        return CellText({
          ...cellContent,
          inputProps: {
            type: 'date',
            validators,
          },
        })
      }
      case 'datetime': {
        const { min, max } = attribute.editorParams

        return CellText({
          ...cellContent,
          inputProps: {
            min: min ? new Date(min) : undefined,
            max: max ? new Date(max) : undefined,
            type: 'datetime',
          },
        })
      }
      case 'smartdate': {
        return CellSmartDate({
          ...cellContent,
          width: 200,
        })
      }
      case 'duration': {
        const { hoursInDay } = attribute.editorParams
        return CellDuration({
          ...cellContent,
          hoursInDay,
          accessor: (item: Asset) => {
            if (!item) return undefined
            const { attributes } = item
            if (!attributes) return undefined

            return attributes[attribute.name]
          },
        })
      }
      case 'email': {
        return CellText({
          ...cellContent,
          width: 150,
          inputProps: {
            type: 'email',
            validators: {
              email: (value: string) => {
                return !regex.is.email.test(value) && regex.exist(value) ? 'Invalide email' : false
              },
            },
          },
        })
      }
      case 'float': {
        const { min, max, step } = attribute.editorParams
        const validators = createMinMaxValidator({ min, max }, 'float', (data) => Number(data))

        return CellText({
          ...cellContent,
          inputProps: {
            type: 'number',
            min,
            max,
            step,
            validators,
          },
        })
      }
      case 'integer': {
        if (attribute.editorType === 'timecode') {
          const { framerate } = column?.editorParams || {}
          return CellTimecode({ ...cellContent, framerate })
        }

        if (attribute.editorType === 'rating') {
          return CellRating({ ...cellContent })
        }

        const { min, max, step, colored } = attribute.editorParams
        const validators = createMinMaxValidator({ min, max }, 'integer', (data) => Number(data))

        return CellText({
          ...cellContent,
          colored,
          noReadStyle: colored,
          inputProps: {
            type: 'number',
            min,
            max,
            step,
            validators: {
              noFloat: (value: string) => {
                const isInt = regex.is.integer.test(value) || !regex.exist(value)
                return !isInt ? `It's not an integer` : false
              },
              ...validators,
            },
          },
        })
      }
      case 'ip': {
        return CellText({
          ...cellContent,
          inputProps: {
            type: 'ip',
            validators: {
              ip: (value: string) => {
                return !regex.is.ip.test(value) && regex.exist(value) ? 'Invalide IP' : false
              },
            },
          },
        })
      }
      case 'text': {
        return CellRichText({
          ...cellContent,
          width: 200,
        })
      }
      case 'time': {
        const { min, max } = attribute.editorParams
        const validators = createMinMaxValidator({ min, max }, 'time')

        return CellText({
          ...cellContent,
          accessor: (item: Asset) => item?.attributes?.[attribute.name],
          inputProps: {
            type: 'time',
            validators,
          },
        })
      }
      case 'url': {
        return CellText({
          ...cellContent,
          width: 150,
          inputProps: {
            type: 'url',
            validators: {
              url: (value: string) => {
                return !regex.is.url.test(value) && regex.exist(value) ? 'Invalide URL' : false
              },
            },
          },
        })
      }
      default:
        console.error(`unknow attribute type ${attribute.attrType}`)
        return null
    }
  }

  function renderParentAttributeCell(column: AttributesColumn) {
    const {
      id,
      isVisible = true,
      hidden,
      description,
      columnName,
      name,
      hiddenable,
      fixable,
      fixed,
      color,
      timestamp,
    } = column
    const attributeId: string = id
    const attribute = attributes[attributeId]

    if (column.editorType !== attribute.editorType) {
      attribute.editorType = column.editorType
      attribute.editorParams = column.editorParams
    }

    const cellContent = {
      id: String(timestamp),
      Header: columnName || name,
      headerColor: color,
      description,
      hiddenable: isBoolean(hiddenable) ? hiddenable : true,
      hiddenByDefault: !isVisible,
      hidden,
      fixable: isBoolean(fixable) ? fixable : true,
      fixed,
      readOnly: true,
      draggable: !trackingSchema?.settings?.lockColumnOrder,
      sortingKey: `parent__attributes__${attribute.name}`,
      Stats: (instance) => {
        let formatValue: (value: any) => React$Node
        let formatLabel: (value: any) => React$Node
        let radialLabel: (values: Object) => React$Node

        if (['rating', 'priority', 'text'].includes(attribute.editorType)) {
          formatLabel = (val) => viewableValue(attribute.attrType, val, attribute.editorType) || 'null'
          if (attribute.editorType === 'rating')
            radialLabel = (values) => padEnd('★'.repeat(Number(values.label)), 5, '☆')
        } else {
          // $FlowFixMe
          formatValue = (val) => {
            if (attribute.editorType === 'smartdate') return val
            return viewableValue(attribute.attrType, val, attribute.editorType) || 'null'
          }
        }

        return (
          <CellStatsAttributes
            instance={instance}
            name={attribute.name}
            formatValue={formatValue}
            formatLabel={formatLabel}
            radialLabel={radialLabel}
            showModal={['smartdate'].includes(attribute.editorType)}
          />
        )
      },
      accessor: `parentInst.attributes.${attribute.name}`,
    }

    const createMinMaxValidator = ({ min, max }, errorLabel, format = (data: any) => data) => {
      const validators: Object = {}
      if (min)
        validators.min = (value) => (format(value) < format(min) ? `${errorLabel} must be greather than ${min}` : false)
      if (max)
        validators.max = (value) => (format(value) > format(max) ? `${errorLabel} must be lower than ${max}` : false)
      return validators
    }

    if (attribute.editorType === 'choice') {
      return CellSelect({
        ...cellContent,
        readMask: (value) => {
          if (attribute.attrType === 'datetime') return dateToLocaleStringFull(value)
          return value
        },
        accessor: (item: Asset) => {
          if (!item) return ''
          const {
            parentInst: { attributes },
          } = item
          if (!attributes) return ''

          if (attribute.attrType === 'integer' && attributes[attribute.name]) {
            return String(attributes[attribute.name])
          }

          if (attribute.attrType === 'datetime' && attributes[attribute.name]) {
            const datetime = new Date(attributes[attribute.name])
            return datetime.toISOString()
          }

          if (attribute.attrType === 'time' && attributes[attribute.name]) {
            return moment(attributes[attribute.name], 'HH:MM:SS').format('HH:MM')
          }

          return attributes[attribute.name]
        },
        options: () => [],
      })
    }

    switch (attribute.attrType) {
      case 'bool': {
        return CellCheckbox({ ...cellContent })
      }
      case 'char': {
        const { colored } = attribute.editorParams

        return CellText({
          ...cellContent,
          colored,
          noReadStyle: colored,
          width: 200,
        })
      }
      case 'date': {
        const validators: Object = {}
        const { min, max } = attribute.editorParams
        if (min) {
          validators.min = (value: Date) => {
            return new Date(value) < new Date(min)
              ? `date must be grather than ${new Date(min).toLocaleDateString()}`
              : false
          }
        }
        if (max) {
          validators.max = (value: Date) => {
            return new Date(value) > new Date(max)
              ? `date must be lower than ${new Date(max).toLocaleDateString()}`
              : false
          }
        }

        return CellText({
          ...cellContent,
          inputProps: {
            type: 'date',
            validators,
          },
        })
      }
      case 'datetime': {
        const { min, max } = attribute.editorParams

        return CellText({
          ...cellContent,
          inputProps: {
            min: min ? new Date(min) : undefined,
            max: max ? new Date(max) : undefined,
            type: 'datetime',
          },
        })
      }
      case 'smartdate': {
        return CellSmartDate({
          ...cellContent,
          width: 200,
        })
      }
      case 'duration': {
        const { hoursInDay } = attribute.editorParams
        return CellDuration({
          ...cellContent,
          hoursInDay,
          accessor: (item: Asset) => {
            if (!item) return undefined
            const {
              parentInst: { attributes },
            } = item
            if (!attributes) return undefined

            return attributes[attribute.name]
          },
        })
      }
      case 'email': {
        return CellText({
          ...cellContent,
          width: 150,
          inputProps: {
            type: 'email',
            validators: {
              email: (value: string) => {
                return !regex.is.email.test(value) && regex.exist(value) ? 'Invalide email' : false
              },
            },
          },
        })
      }
      case 'float': {
        const { min, max, step } = attribute.editorParams
        const validators = createMinMaxValidator({ min, max }, 'float', (data) => Number(data))

        return CellText({
          ...cellContent,
          inputProps: {
            type: 'number',
            min,
            max,
            step,
            validators,
          },
        })
      }
      case 'integer': {
        if (attribute.editorType === 'timecode') {
          const { framerate } = column?.editorParams || {}
          return CellTimecode({ ...cellContent, framerate })
        }

        if (attribute.editorType === 'rating') {
          return CellRating({ ...cellContent })
        }

        const { min, max, step, colored } = attribute.editorParams
        const validators = createMinMaxValidator({ min, max }, 'integer', (data) => Number(data))

        return CellText({
          ...cellContent,
          colored,
          noReadStyle: colored,
          inputProps: {
            type: 'number',
            min,
            max,
            step,
            validators: {
              noFloat: (value: string) => {
                const isInt = regex.is.integer.test(value) || !regex.exist(value)
                return !isInt ? `It's not an integer` : false
              },
              ...validators,
            },
          },
        })
      }
      case 'ip': {
        return CellText({
          ...cellContent,
          inputProps: {
            type: 'ip',
            validators: {
              ip: (value: string) => {
                return !regex.is.ip.test(value) && regex.exist(value) ? 'Invalide IP' : false
              },
            },
          },
        })
      }
      case 'text': {
        return CellRichText({
          ...cellContent,
          width: 200,
        })
      }
      case 'time': {
        const { min, max } = attribute.editorParams
        const validators = createMinMaxValidator({ min, max }, 'time')

        return CellText({
          ...cellContent,
          accessor: (item: Asset) => item?.parentinst?.attributes?.[attribute.name],
          inputProps: {
            type: 'time',
            validators,
          },
        })
      }
      case 'url': {
        return CellText({
          ...cellContent,
          width: 150,
          inputProps: {
            type: 'url',
            validators: {
              url: (value: string) => {
                return !regex.is.url.test(value) && regex.exist(value) ? 'Invalide URL' : false
              },
            },
          },
        })
      }
      default:
        console.error(`unknow attribute type ${attribute.attrType}`)
        return null
    }
  }

  function renderDynamicApprovalCell(gettedColumn: DynamicApprovalColumn) {
    const dynamicApproval = dynamicApprovals[gettedColumn.id]
    const column = { ...gettedColumn, ...dynamicApproval }

    const {
      columnName,
      isVisible = true,
      hidden,
      name,
      description,
      hiddenable,
      readOnly,
      fixable,
      fixed,
      id,
      timestamp,
      targetStep,
      color,
    } = column
    const dynamicApprovalId: ID = id

    return CellDynamicApproval({
      id: String(timestamp),
      Header: columnName || name,
      headerColor: color,
      headerSubLabel: targetStep ? <span className="grey">{` → ${steps[targetStep]?.name}`}</span> : null,
      description,
      searchFilterLabel: `${columnName || name} - comment`,
      hiddenable: isBoolean(hiddenable) ? hiddenable : true,
      hiddenByDefault: !isVisible,
      hidden,
      fixable: isBoolean(fixable) ? fixable : true,
      fixed,
      projectId,
      sortingKey: [
        { label: 'Status', key: `dynamicApproval_${dynamicApprovalId}_status` },
        { label: 'Author', key: `dynamicApproval_${dynamicApprovalId}_author__name` },
        { label: 'Status updated at', key: `dynamicApproval_${dynamicApprovalId}_statusUpdatedAt` },
        { label: 'Created at', key: `dynamicApproval_${dynamicApprovalId}_createdAt` },
        { label: 'Comment', key: `dynamicApproval_${dynamicApprovalId}_comment` },
      ],
      readOnly: !permissions.dynApp || (isBoolean(readOnly) ? readOnly : false),
      draggable: !trackingSchema?.settings?.lockColumnOrder,
      accessor: (item: Asset, index: number) => {
        if (!item || !item.dynamicApprovalValuesInst) return undefined
        let dynamicApproval = find(item.dynamicApprovalValuesInst, ({ dynamicApproval }) => {
          return dynamicApproval === dynamicApprovalId
        })

        if (!dynamicApproval) {
          dynamicApproval = {
            dynamicApproval: dynamicApprovalId,
            asset: item.id,
          }
        }

        return dynamicApproval
      },
      Stats: (instance) => {
        return (
          <CellStatsDynamicApprovals
            instance={instance}
            dynamicApprovalId={dynamicApprovalId}
            columnName={columnName}
          />
        )
      },
      actions: (instance, cell) => {
        const { value: dynamicApproval } = cell
        // $FlowFixMe
        return ['edit', dynamicApproval.id ? 'copy' : null, 'past', dynamicApproval.id ? 'delete' : null].filter(
          (_) => _,
        )
      },
      extends: {
        progressionStatus,
        parentId: asset?.id,
        id: dynamicApprovalId,
        pushType: 'dynamicApprovals',
        targetStep,
      },
      save: {
        resource: 'dynamicApprovalValues',
        formatData: (item, value, cell, instance, type) => {
          const { id, dynamicApproval, asset } = cell.value
          const user = store.getState()?.user

          if (type === 'delete') return id
          if (!value) return null

          return {
            id,
            asset,
            dynamicApproval,
            status: value.status,
            comment: value.comment,
            author: user.asset,
          }
        },
      },
    })
  }

  function renderAggregated(column: AggregatedColumn) {
    const { aggregateType, columnName, name, timestamp, hiddenable, isVisible = true, hidden, fixable, fixed } = column

    if (aggregateType === 'calculatedField') {
      const { aggregateDatas = {} } = column
      const { name: _name } = aggregateDatas

      const accessor = `aggregations.calculatedFields.${_name}`
      const { description, color, calculatedFieldType } = column

      const cellContent = {
        Header: columnName || name,
        headerColor: color,
        description,
        hiddenable: isBoolean(hiddenable) ? hiddenable : true,
        hiddenByDefault: !isVisible,
        hidden,
        fixable: isBoolean(fixable) ? fixable : true,
        fixed,
        readOnly: true,
        draggable: !trackingSchema?.settings?.lockColumnOrder,
        id: String(timestamp),
        accessor,
        actions: () => [],
      }

      switch (calculatedFieldType) {
        case 'duration': {
          return CellAggregatedAttribute({
            ...cellContent,
            attrName: 'duration',
            attrType: 'duration',
            editorType: 'duration',
          })
        }

        case 'dynappsummary':
          return CellAggregatedDynamicApproval({
            ...cellContent,
            accessor: `${accessor}.status__counts`,
            extends: {
              progressionStatus,
            },
          })

        default:
          throw new Error(`unknow calculated field type ${calculatedFieldType}`)
      }
    }

    if (aggregateType === 'assetLink') {
      const { category, description, length } = column

      if (length) {
        return CellText({
          Header: columnName || name,
          description,
          id: String(timestamp),
          hiddenable: isBoolean(hiddenable) ? hiddenable : true,
          hiddenByDefault: !isVisible,
          hidden,
          fixable: isBoolean(fixable) ? fixable : true,
          fixed,
          readOnly: true,
          draggable: !trackingSchema?.settings?.lockColumnOrder,
          accessor: (item) => {
            if (!item) return []
            return (filter(item?.aggregations?.assetLinks, (link) => link?.attributes?.category === category) || [])
              .length
          },
          width: 30,
        })
      }

      return CellAssetsList({
        Header: columnName || name,
        description,
        id: String(timestamp),
        disableClickableAssets: restrictedActions.includes('assetSheet'),
        hiddenable: isBoolean(hiddenable) ? hiddenable : true,
        hiddenByDefault: !isVisible,
        hidden,
        fixed,
        fixable: isBoolean(fixable) ? fixable : true,
        accessor: (item: Object) => {
          if (!item) return []
          return filter(item?.aggregations?.assetLinks, (link) => link?.attributes?.category === category) || []
        },
        width: 200,
        aggregated: true,
        readOnly: true,
        draggable: !trackingSchema?.settings?.lockColumnOrder,
        category,
        actions: () => [],
        onOpenItem: (asset) => {
          router.goTo('index', { assetId: asset.id }, { rightPanel: true })
        },
      })
    }

    if (aggregateType === 'attributes') {
      const { aggregateDatas = {} } = column
      const { name: attrName, attrType, editorType, description } = aggregateDatas

      return CellAggregatedAttribute({
        Header: columnName || name,
        description,
        id: String(timestamp),
        hiddenable: isBoolean(hiddenable) ? hiddenable : true,
        hiddenByDefault: !isVisible,
        hidden,
        fixed,
        fixable: isBoolean(fixable) ? fixable : true,
        readOnly: true,
        draggable: !trackingSchema?.settings?.lockColumnOrder,
        accessor: (item: Object) => {
          return item?.aggregations?.attributes?.[attrName]
        },
        attrType,
        editorType,
        width: 200,
        attrName,
        actions: () => [],
      })
    }

    const { category, description } = column

    return CellFlags({
      Header: columnName || name,
      description,
      id: String(timestamp),
      category,
      getResourceID: (cell) => cell.row.original?.id,
      foreignKey: 'asset',
      hiddenable: isBoolean(hiddenable) ? hiddenable : true,
      hiddenByDefault: !isVisible,
      hidden,
      fixed,
      fixable: isBoolean(fixable) ? fixable : true,
      aggregated: true,
      readOnly: true,
      draggable: !trackingSchema?.settings?.lockColumnOrder,
      accessor: (item: Object) => {
        const flags: ResourcesList<FlagRelation> = {}
        forEach(item?.aggregations?.flags || [], (flag: Flag) => {
          flags[flag.id] = { asset: item.id, flag: flag.id, id: '', flagInst: flag }
        })
        return flags
      },
      width: 200,
      actions: () => [],
    })
  }

  function renderStepCell(column: StepColumn): Column {
    const {
      id,
      timestamp,
      columnName,
      name,
      description,
      color,
      readOnly,
      hiddenable,
      isVisible = true,
      hidden,
      fixed,
      fixable,
    } = column

    return CellTask({
      id: String(timestamp),
      Header: columnName || name,
      description,
      headerColor: color,
      hideTaskMenu: restrictedActions.includes('taskSheet'),
      readOnly: !permissions.steps || (isBoolean(readOnly) ? readOnly : false),
      draggable: !trackingSchema?.settings?.lockColumnOrder,
      hiddenable: isBoolean(hiddenable) ? hiddenable : true,
      hiddenByDefault: !isVisible,
      hidden,
      fixed,
      fixable: isBoolean(fixable) ? fixable : true,
      searchFilterLabel: `${columnName || name} - brief`,
      accessor: (item: Asset) => {
        if (!item) return undefined
        return find(item.tasksInst, (task) => task.step === id)
      },
      Stats: (instance, props) => {
        return (
          <CellStatsStep
            instance={instance}
            progressionStatus={progressionStatus}
            step={id}
            steps={steps}
            columnName={columnName || name}
          />
        )
      },
      Placeholder: (instance) => {
        const task = find(instance.cell.row.original.tasksInst, (task) => task.step === id)
        if (!task) return <div className="fullHeight flex center alignCenter lightgrey fontSize11">No task</div>
        return undefined
      },
      sortingKey: [
        { label: 'Status', key: `lastTake_${id}_status` },
        { label: 'Take number', key: `lastTake_${id}_number` },
        { label: 'Estimated length', key: `lastTake_${id}_estimLength` },
        { label: 'Updated at', key: `lastTake_${id}_updatedAt` },
        { label: 'Status updated at', key: `lastTake_${id}_statusUpdatedAt` },
        { label: 'Comment', key: `lastTake_${id}_comment` },
      ],
      step: steps[id],
      actions: (instance, cell) => ['edit', 'copy', 'past'],
      extends: {
        progressionStatus,
        parentId: asset?.id,
        assetType: trackingSchema?.assetType,
        id,
        pushType: 'steps',
      },
      save: {
        resource: 'takes',
        formatData: (item, value, cell, instance, type) => {
          const task = find(item.tasksInst, (task) => task.step === id)
          if (!task) return null
          const { flags, ...takeValues } = value
          delete takeValues.task

          const lastTake = getLastTakeFromTask(task)
          if (Object.keys(value).length === 0 || !lastTake) return null

          const diffTake = {}

          if (type === 'past') {
            const { copiedCells } = instance.state
            const copiedCell = map(copiedCells)[0]
            if (!copiedCell) return null
            const lastCopiedTake = getLastTakeFromTask(copiedCell.value)
            if (!lastCopiedTake) return null
            diffTake.comment = lastCopiedTake.comment
            diffTake.status = lastCopiedTake.status
          } else {
            forEach(takeValues, (value, key) => {
              if (lastTake && value !== lastTake[key]) diffTake[key] = value
              if (!lastTake) diffTake[key] = value
            })
          }

          return {
            type: 'recursive',
            requests: [
              diffTake ? () => ({ resource: 'takes', toUpdate: { ...diffTake, id: lastTake.id } }) : null,
              flags
                ? (res) => {
                    const newFlags = map(flags, ({ flagInst }) => ({ take: lastTake.id, flag: flagInst.id }))
                    const { toCreate, toDelete } = createUpdatingFlagsPromises(
                      newFlags,
                      map(lastTake.takeFlagsInst),
                      'takeFlags',
                      type,
                    )
                    return { resource: 'takeFlags', toCreate, toDelete }
                  }
                : undefined,
            ].filter((_) => _),
          }
        },
      },
    })
  }

  function renderCalculatedFieldCell(column: CalculatedFieldColumn): Column {
    const {
      columnName,
      name,
      description,
      color,
      hiddenable,
      isVisible = true,
      hidden,
      fixed,
      fixable,
      timestamp,
      id,
    } = column

    const calculatedFieldType = getCalculatedFieldType(column)

    const cellContent = {
      Header: columnName || name,
      headerColor: color,
      description,
      hiddenable: isBoolean(hiddenable) ? hiddenable : true,
      hiddenByDefault: !isVisible,
      hidden,
      sortingKey: `calculatedField_${id}`,
      fixable: isBoolean(fixable) ? fixable : true,
      fixed,
      readOnly: true,
      draggable: !trackingSchema?.settings?.lockColumnOrder,
      id: String(timestamp),
      Stats: (instance) => <CellStatsCalculatedFields instance={instance} id={id} />,
      accessor: (item) => {
        return item?.calculatedFieldsValueInst?.find((cf) => cf.calculatedField === id)?.value
      },
      actions: () => [],
    }

    switch (calculatedFieldType) {
      case 'number': {
        return CellText({
          ...cellContent,
          cellExponent: (instance, cell) => {
            const { updatedAt } =
              cell.row.original?.calculatedFieldsValueInst?.find((cf) => cf.calculatedField === id) || {}
            if (!updatedAt) return undefined
            return (
              <Tooltip title={`Last update ${moment(updatedAt).format('DD/MM/YYYY HH:mm')}`}>
                <span className="grey fontSize10">{moment(updatedAt).format('DD/MM/YYYY HH:mm')}</span>
              </Tooltip>
            )
          },
        })
      }
      case 'duration':
        return CellDuration({
          ...cellContent,
          cellExponent: (instance, cell) => {
            const { updatedAt } =
              cell.row.original?.calculatedFieldsValueInst?.find((cf) => cf.calculatedField === id) || {}
            if (!updatedAt) return undefined
            return (
              <Tooltip title={`Last update ${moment(updatedAt).format('DD/MM/YYYY HH:mm')}`}>
                <span className="grey fontSize10">{moment(updatedAt).format('DD/MM/YYYY HH:mm')}</span>
              </Tooltip>
            )
          },
        })
      case 'dynappsummary': {
        return CellDynamicApproval({
          ...cellContent,
          projectId,
          isSummary: true,
          noFilterOption: true,
          extends: {
            progressionStatus,
            parentId: '',
            id: '',
            pushType: 'dynamicApprovals',
          },
        })
      }

      default:
        throw Error('Unknown calculated field type')
    }
  }

  function makeColumns(): Array<ColumnHeader> {
    return [
      {
        Header: 'Asset',
        id: 'headerAsset',
        columns: [
          CellLink({
            Header: 'Asset',
            id: 'asset1',
            fixed: 'left',
            hiddenable: false,
            readOnly: true,
            draggable: !trackingSchema?.settings?.lockColumnOrder,
            sortingKey: 'name',
            showRemoved: true,
            unselectable: true,
            disableClick: restrictedActions.includes('assetSheet'),
            accessor: 'name',
            onClick: (row, e) => router.goTo('index', { assetId: row.original.id }, { rightPanel: true }),
          }),
        ],
      },
      ...(trackingSchema
        ? trackingSchema.schema.map((groupColumn: Object) => {
            return {
              Header: groupColumn.groupName,
              description: groupColumn.description,
              headerColor: groupColumn.color,
              id: groupColumn.groupName,
              columns: groupColumn.items.map((column: Object) => {
                switch (column.type) {
                  case 'lastTake':
                    return renderLastTakeCell(column)
                  case 'assetLinks':
                    return renderAssetLinks(column)
                  case 'assets':
                    return renderAssetCell(column)
                  case 'postBoardNotes':
                    return renderNotesCell(column)
                  case 'tasks':
                    return renderTasksCell(column)
                  case 'attributes':
                    return renderAttributeCell(column)
                  case 'parentAttributes':
                    return renderParentAttributeCell(column)
                  case 'steps':
                    return renderStepCell(column)
                  case 'dynamicApprovals':
                    return renderDynamicApprovalCell(column)
                  case 'aggregation':
                    return renderAggregated(column)
                  case 'calculatedField':
                    return renderCalculatedFieldCell(column)

                  default:
                    throw new Error(`Can't find this column type (${column.type})`)
                }
              }),
            }
          })
        : []),
      ColumnTimeline({
        accessor: 'tasksInst',
        CellComponent: TimelineCellComponent,
        Drawer: TimelineDrawerComponentActivities,
        timelineEdition: (task: Task, instance) => {
          if (!task) return undefined

          const color = (ganttColorsType === 'status' ? task?.statusInst?.color : task?.stepInst?.color) || 'grey'

          return {
            resource: 'tasks',
            id: task.id,
            color,
            label: `${task.stepInst?.name} - ${task.assignedUserInst?.name || task.suggestedUserInst?.name || ''}`,
            contextMenuItems: [
              {
                label: 'Fit to activities',
                onClick: () => fitToActivities(task, instance),
                disabled: !permissions.timeline.startEndUpdate,
              },
            ],
            start: {
              position: 'start',
              attr: 'startDate',
              formatValue: (date) => date.toISOString(),
              readOnly: !permissions.timeline.startEndUpdate,
            },
            end: {
              position: 'end',
              attr: 'endDate',
              formatValue: (date) => date.toISOString(),
              readOnly: !permissions.timeline.startEndUpdate,
            },
            earliest: {
              position: 'earliest',
              attr: 'earliestStartDate',
              formatValue: (date) => date.toISOString(),
              readOnly: !permissions.timeline.earliestLatestUpdate,
            },
            latest: {
              position: 'latest',
              attr: 'latestEndDate',
              formatValue: (date) => date.toISOString(),
              readOnly: !permissions.timeline.earliestLatestUpdate,
            },
          }
        },
      }),
    ]
  }

  function toggleButtons(instance) {
    const buttons = []

    const showConfig = permission(
      [
        'projet_follow-up_table management_create',
        'projet_follow-up_table management_update',
        'projet_follow-up_table management_delete',
      ],
      'or',
    )

    if (showConfig && trackingSchema) {
      buttons.push({
        key: 'config',
        children: 'Config',
        onClick: () => history.push(`/projects/${projectId}/settings/follow-up/${trackingSchema.id}`),
        icon: 'fas-cog',
      })
    }

    if (permissions.timeline.read) {
      buttons.push({
        key: 'showGantt',
        tooltip: 'Show timeline',
        icon: 'fas-stream',
        selected: showGantt,
        onClick: (e) =>
          setShowGantt((sg) => {
            setUserSettings(`${tableId || 'table-follow-up-'}-showGantt`, !sg)
            return !sg
          }),
        options: [
          {
            key: 'showGantt-status',
            label: 'Status color',
            onClick: () => {
              setShowGantt(true)
              setGanttColorsType('status')
              setUserSettings(`${tableId || 'table-follow-up-'}-ganttColors`, 'status')
            },
            icon: 'fas-traffic-light',
            selected: ganttColorsType === 'status',
          },
          {
            key: 'showGantt-step',
            label: 'Step color',
            onClick: () => {
              setShowGantt(true)
              setGanttColorsType('step')
              setUserSettings(`${tableId || 'table-follow-up-'}-ganttColors`, 'step')
            },
            icon: 'fas-tasks',
            selected: ganttColorsType === 'step',
          },
        ],
      })
    }

    const showSendEvent = permission(['projet_follow-up__send events to sentry'])
    if (showSendEvent && trackingSchema) {
      buttons.push({
        key: 'sendEvent-ttPlayer',
        label: 'Open in TTPlayer',
        icon: 'fas-film',
        onClick: () => {
          SendEvent(instance, 'ttplayer')
        },
        options: [
          {
            key: 'sendEvent-askSofts',
            label: 'Send to tools',
            onClick: () => {
              SendEvent(instance, 'askSoft')
            },
            icon: 'fas-terminal',
          },
        ],
      })
    }

    return buttons
  }

  const filters = useMemo(() => {
    if (trackingSchema) {
      return new Filters({
        columns: flatten(trackingSchema.schema.map((groupColumn: Object) => groupColumn.items)),
        attributes,
        assetType: trackingSchema.assetType,
      }).getFilters()
    }
    return []
  }, [trackingSchema, attributes])

  const columns: Array<ColumnHeader> = useMemo(trackingSchema ? makeColumns : () => [], [
    trackingSchema,
    asset,
    ganttColorsType,
  ])

  const exportExcelFormat: ?ExportExcelFormat<{ trackingSchema: TrackingSchema }> = useMemo(() => {
    if (asset && trackingSchema) {
      return {
        assetId: asset.id,
        data: { trackingSchema },
        type: 'trackingSchema',
      }
    }
    return undefined
  }, [asset, trackingSchema])

  const [key, setKey] = useState(tableId)

  useEffect(() => {
    if (resetTable && prefs.current) {
      prefs.current.softResetTable(tableId)
      history.updateQuery({ resetTable: null })
      setKey(Date.now())
    }
  }, [resetTable, prefs])

  useEffect(() => {
    setKey(tableId)
    setShowGantt(getUserSettings(`${tableId || 'table-follow-up-'}-showGantt`))
    setGanttColorsType(getUserSettings(`${tableId || 'table-follow-up-'}-ganttColors`))
  }, [tableId])

  const { directoryId, assetId } = useMemo(() => {
    if (asset) {
      if (['sh', 'mo'].includes(asset.assetType)) {
        return { directoryId: asset.parent, assetId: asset.id }
      }
      return { directoryId: asset.id, assetId: undefined }
    }
    return { directoryId: undefined, assetId: undefined }
  }, [asset])

  const resourcesParams = useMemo(() => {
    if (!directoryId || !trackingSchema) return null
    return {
      resourceType: 'assets',
      requestName: 'fetchDescendantWithTrackingDatas',
      includedResources,
      requestData: {
        id: directoryId,
        trackingSchemaId: trackingSchema.id,
      },
      queries: {
        uuid__in: assetId,
      },
    }
  }, [trackingSchema, directoryId, assetId])

  return (
    <Table
      key={key}
      filters={filters}
      allFilters={allFilters}
      showLockFilters={true}
      lockFilters={lockFilters}
      resourcesParams={resourcesParams}
      toggleButtons={toggleButtons}
      getPaginatedList={setPaginatedList}
      reduxData={values(assets)}
      extendToolbar={extendToolbar}
      enableStats={!restrictedActions.includes('stats')}
      tableId={tableId}
      projectId={projectId}
      exportExcelFormat={restrictedActions.includes('exports') ? undefined : exportExcelFormat}
      columns={columns}
      rowExpander={true}
      isGantt={permissions.timeline.read ? showGantt : false}
      showGantt={permissions.timeline.read ? showGantt : false}
      getTimelineItems={(item) =>
        item?.tasksInst.flatMap((task: Task) => [
          task,
          map(task.activitiesInst, ({ date }) => ({ startDate: date, endDate: date })),
        ])
      }
      getPrefs={(_prefs) => {
        prefs.current = _prefs
      }}
      extendDatas={{ asset, trackingSchemaId: trackingSchema?.isExternal ? trackingSchema.id : undefined }}
    />
  )
}
