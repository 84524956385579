var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { MUIAutocomplete } from 'app/components/Form';
import { useEffect } from 'react';
import { filter, sortBy, map } from 'lodash';
import { createExternalJob } from 'app/store/reducers/externalJobs';
import { useDispatch } from 'react-redux';
export function ExternalJob(props) {
    const { ref, value, externalJobs } = props, autocompleteProps = __rest(props, ["ref", "value", "externalJobs"]);
    const dispatch = useDispatch();
    function onSearch(searchValue) {
        return __awaiter(this, void 0, void 0, function* () {
            const filteredResults = filter(externalJobs, (job) => job.name.toLowerCase().includes(searchValue.toLowerCase()));
            const output = sortBy(map(filteredResults, (job) => ({ value: job.id, label: job.name, data: { jobId: job.id, job } })), ['label']);
            const sameItem = output.find(({ label }) => label === searchValue);
            if (!sameItem) {
                output.push({
                    value: searchValue,
                    label: searchValue,
                    labelCustom: `Create Item "${searchValue}"`,
                    data: { toCreate: true, job: { name: searchValue } },
                });
            }
            return output;
        });
    }
    useEffect(() => {
        ;
        (() => __awaiter(this, void 0, void 0, function* () {
            var _a;
            if ((_a = value === null || value === void 0 ? void 0 : value.data) === null || _a === void 0 ? void 0 : _a.toCreate) {
                // @ts-ignore
                const { payload: response } = yield dispatch(createExternalJob(value.data.job.name));
                value.value = response.id;
                value.data.jobId = response.id;
                value.data.job = response;
                value.data.toCreate = false;
            }
        }))();
    }, [value]);
    return (_jsx(MUIAutocomplete, Object.assign({ dataCy: "externaljob", inputRef: ref, clearOnEscape: false, value: value, onSearch: onSearch, fullWidth: true, syncSearch: true }, autocompleteProps)));
}
