import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import classes from './Projects.module.scss';
import { userProjectsSelector } from 'app/store/selectors/user';
import ProjectCard from './ProjectCard/ProjectCard';
function Projects() {
    const projects = useSelector(userProjectsSelector);
    return (_jsx("div", { className: classes.container, "data-testid": "page-project", children: _jsx("ul", { className: classes.projects, children: projects
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((project) => (_jsx(ProjectCard, { project: project }))) }) }));
}
export default Projects;
