// @flow
import React from 'react'
import moment from 'moment'
import { map, sortBy } from 'lodash'
import ModalEditTake from 'app/containers/Take/ModalEditTake/ModalEditTake.tsx'
import getUserNameFromAsset from 'app/core/utils/getUserNameFromAsset'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { openModal } from 'app/components/Modal'
import { router } from 'app/containers/Task/router'
import TextRead from 'app/components/TextEditor/TextRead.jsx'
import { Tooltip } from 'app/components/Tooltip/Tooltip.jsx'
import type { ID, Task, Take, ResourcesList, ProgressionStatus, Step, AssetTypes } from 'app/core/types'
import type { DefaultCellProps, Column, PrefsProps } from 'app/components/Table/types.js'
import { getLastTakeFromTask } from 'app/pages/Project/ProductionManagement/FollowUp/utils.jsx'
import { permission } from 'app/containers/Permissions/'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'

import { pushToActions } from '../pushTo/pushToActions'
import { pushToColumnActions } from '../pushTo/pushToColumnActions'
import { FastScheduler } from './FastScheduler.jsx'
import { groupingFns } from './groupingFns.jsx'

import classes from './CellTask.module.scss'

export type CellTaskParams = {|
  ...DefaultCellProps,
  step: Step,
  hideTaskMenu?: boolean,
  extends: {|
    progressionStatus: ResourcesList<ProgressionStatus>,
    parentId: ?ID,
    assetType?: AssetTypes,
    id: string,
    pushType: 'dynamicApprovals' | 'steps',
  |},
|}

type ReadProps = {|
  isRowExpanded: boolean,
  task: ?Task,
  lastTake: ?Take,
  prefs: PrefsProps,
|}

function ReadDisplayMin(props: ReadProps) {
  const { isRowExpanded, task, lastTake, prefs } = props
  const { minLineHeight, maxLineHeight } = prefs

  if (!task || !lastTake) return <div className="grey flex center alignCenter fullHeight fullWidth">No task</div>

  const { number, statusInst = {}, comment } = lastTake || {}
  const { name: statusName, color, statusType } = statusInst || {}

  const isHatched = number === 1 && statusType === 0
  const hacthedStyle = isHatched
    ? { backgroundColor: color, padding: '0 5px', borderRadius: 4, color: getColorFromBackground(color) }
    : { color: getColorFromBackground(color) }

  return (
    <div
      className={classes.readContainer}
      style={{
        background: isHatched
          ? `repeating-linear-gradient(120deg, ${color}, ${color} 6px, ${color}99 6px, ${color}99 12px)`
          : color,
        maxHeight: (isRowExpanded ? maxLineHeight : minLineHeight) - 2,
      }}
    >
      <div className="flex row noWrap fullWidth fullHeight alignCenter">
        {comment ? (
          <Tooltip
            interactive={true}
            style={{ minWidth: 300, maxHeight: '80vh' }}
            placement="left"
            title={
              <div className="flex wrap fullHeight overflowYAuto" style={{ maxHeight: 'calc(80vh - 30px)' }}>
                <TextRead text={comment} />
              </div>
            }
          >
            <div>
              <FontIcon icon="fas-pen" style={{ color: '#4a4a4a' }} />
            </div>
          </Tooltip>
        ) : null}
        <div className="flex row spaceBetween flex1 marginLeft10">
          <span style={hacthedStyle}>{number}</span>
          <div style={hacthedStyle} className="bold">
            {statusName}
          </div>
          {task.assignedUser && (
            <span className={classes.assignedUser} style={hacthedStyle}>
              {getUserNameFromAsset(task.assignedUserInst)}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

function ReadDisplayAll(props: ReadProps) {
  const { isRowExpanded, task, lastTake, prefs } = props
  const { minLineHeight, maxLineHeight } = prefs

  if (!task || !lastTake) return <div className="grey flex center alignCenter fullHeight fullWidth">No task</div>

  const { number, statusInst = {}, comment, statusUpdatedAt, updatedAt } = lastTake
  const { name: statusName, color, statusType } = statusInst || {}
  const statusDate = statusUpdatedAt ? moment(new Date(statusUpdatedAt)).format('DD/MM/YYYY HH:mm') : null
  const taskDate = updatedAt ? moment(new Date(updatedAt)).format('DD/MM/YYYY HH:mm') : null

  const isHatched = number === 1 && statusType === 0
  const hacthedStyle = isHatched
    ? { backgroundColor: color, padding: '0 5px', borderRadius: 4, color: getColorFromBackground(color) }
    : { color: getColorFromBackground(color) }

  const taskUpdatedDateEl = (
    <div className="flex row wrap">
      {updatedAt ? (
        <Tooltip title={`Last task update: ${taskDate || ''}`}>
          <span className={classes.date} style={hacthedStyle}>
            <FontIcon icon="fas-tasks" className="marginRight5" style={{ color: getColorFromBackground(color) }} />{' '}
            <span>{taskDate}</span>
          </span>
        </Tooltip>
      ) : null}
    </div>
  )

  const statusUpdatedAtEl = statusUpdatedAt ? (
    <Tooltip title={`Last status update: ${statusDate || ''}`}>
      <span className={classes.date} style={{ ...hacthedStyle, position: 'absolute', bottom: -18 }}>
        <FontIcon icon="fas-traffic-light" className="marginRight5" style={{ color: getColorFromBackground(color) }} />{' '}
        <span>{statusDate}</span>
      </span>
    </Tooltip>
  ) : null

  return (
    <div
      className={classes.readContainer}
      style={{
        background: isHatched
          ? `repeating-linear-gradient(120deg, ${color}, ${color} 6px, ${color}99 6px, ${color}99 12px)`
          : color,
        color: getColorFromBackground(color),
        maxHeight: (isRowExpanded ? maxLineHeight : minLineHeight) - 2,
        overflow: 'hidden',
      }}
    >
      <div className={classes.top} style={{ height: isRowExpanded ? 70 : minLineHeight }}>
        <div className={`${classes.title} flex row noWrap spaceBetween`}>
          <span style={hacthedStyle} className="textNoWrap">
            Take {number}
          </span>

          {task.assignedUser && (
            <span className={classes.assignedUser} style={hacthedStyle}>
              {getUserNameFromAsset(task.assignedUserInst)}
            </span>
          )}

          {taskUpdatedDateEl}
        </div>

        <div className={`${classes.status} flex center column alignCenter`}>
          <div style={hacthedStyle}>{statusName}</div>
          {(!isRowExpanded && minLineHeight > 55) || isRowExpanded ? statusUpdatedAtEl : null}
        </div>
      </div>
      {!isRowExpanded && comment ? (
        <Tooltip
          interactive={true}
          style={{ minWidth: 300, maxHeight: '80vh' }}
          placement="left"
          title={
            <div className="flex wrap fullHeight overflowYAuto" style={{ maxHeight: 'calc(80vh - 30px)' }}>
              <TextRead text={comment} />
            </div>
          }
        >
          <div className={classes.isCollapsedTextIcon}>
            <FontIcon icon="fas-pen" style={{ color: getColorFromBackground(color) }} />
          </div>
        </Tooltip>
      ) : null}
      {isRowExpanded ? (
        <TextRead
          text={comment || ''}
          className={classes.comment}
          preventImageReflow={true}
          containerProps={{
            style: {
              maxHeight: `calc(100% - ${isRowExpanded ? 70 : minLineHeight}px)`,
              overflowY: 'auto',
              borderRadius: '4px',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              color: '#4a4a4a',
              ...(isHatched
                ? {
                    paddingTop: 20,
                    borderRadius: '4px',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    color: '#4a4a4a',
                  }
                : {}),
            },
          }}
          style={{ height: isHatched ? '100%' : 'initial' }}
        />
      ) : null}
    </div>
  )
}

const Read = (props: ReadProps) => {
  const { isRowExpanded, prefs } = props
  const { minLineHeight, maxLineHeight } = prefs

  if (isRowExpanded && maxLineHeight < 150) {
    return ReadDisplayAll({ ...props, isRowExpanded: false })
  }
  if (!isRowExpanded && minLineHeight < 49) {
    return ReadDisplayMin(props)
  }
  return ReadDisplayAll(props)
}

export const CellTask = ({
  actions,
  step,
  extends: _extends,
  hideTaskMenu,
  readOnly,
  ...data
}: CellTaskParams): Column => {
  return {
    cellType: 'CellTask',
    Cell: (instance) => {
      const { cell, prefs } = instance
      const { value: task, getCellProps } = cell
      const { isRowExpanded } = getCellProps()
      const lastTake = getLastTakeFromTask(task)

      return <Read task={task} lastTake={lastTake} isRowExpanded={isRowExpanded} prefs={prefs} />
    },
    EditModal: (instance) => {
      const {
        cell,
        state: { selectedCells },
        cellPositionLabel,
      } = instance
      const { getCellProps, value: task } = cell
      const { edition } = getCellProps()
      const { endEdit, save } = edition
      const lastTake = getLastTakeFromTask(task)

      return (
        <ModalEditTake
          multipleCellsEdit={Object.keys(selectedCells || {}).length > 1}
          take={lastTake}
          taskId={task && task.id}
          onSave={save}
          onRequestClose={endEdit}
          exponentTitle={cellPositionLabel}
        />
      )
    },
    readOnly,
    actions: (instance, cell) => {
      const { getCellProps, value: task, column } = cell
      const { edition } = getCellProps()
      const { save } = edition
      const { progressionStatus } = _extends

      if (!task) return []

      const resolvedActions = (typeof actions === 'function' ? actions(instance, cell) : actions) || []
      if (!readOnly) {
        if (task) {
          resolvedActions.push(
            { separator: true },
            {
              label: 'Status',
              items: map(sortBy(progressionStatus, ['order']), (opt) => ({
                onClick: () => save({ status: opt.id }),
                editAction: true,
                label: opt.name,
                color: opt.color,
                hotKeys: [String(opt.order)],
                rightLabel: String(opt.order),
              })),
              editAction: true,
            },
          )
        }
        resolvedActions.push(
          { separator: true },
          ...pushToActions(column.id, instance, cell),
          { separator: true },
          {
            label: 'Create new take',
            rightLabel: 'Ctrl + N',
            onClick: (event) => {
              openModal(
                <ModalEditTake
                  taskId={task.id}
                  onChange={(values) => {
                    const { updateCells } = instance
                    updateCells({ [cell.id]: cell })
                  }}
                />,
              )
            },
            hotKeys: ['ctrl+n', 'command+n'],
            editAction: true,
          },
        )
      }

      if (!hideTaskMenu) {
        resolvedActions.push({
          label: 'Show task details',
          rightLabel: 'Ctrl + O',
          onClick: (event) => {
            event.preventDefault()
            router.goTo('index', { taskId: task.id }, { rightPanel: true })
          },
          hotKeys: ['ctrl+o', 'command+o'],
          editAction: false,
        })
      }

      return resolvedActions
    },
    onPastEvent: (value, instance, cell) => {
      const { save } = cell.getCellProps().edition
      return save({ ...cell.value, comment: value || '' })
    },
    groupingFns: groupingFns(_extends.progressionStatus),
    headerActions: (instance, column) => {
      const { assetType, parentId } = _extends
      const headerActions = []

      headerActions.push(...pushToColumnActions(column.id, instance, column))

      if (assetType && parentId && permission(['projet_follow-up__task scheduler'])) {
        headerActions.push(
          { separator: true },
          {
            label: `Schedule ${step?.name} tasks`,
            onClick: (event) => {
              openModal(
                <FastScheduler defaultStep={step} instance={instance} assetType={assetType} rootAsset={parentId} />,
              )
            },
            editAction: true,
          },
        )
      }

      return headerActions
    },
    extends: _extends,
    width: 350,
    ...data,
  }
}
