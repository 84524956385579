// @flow

import * as React from 'react'
import { TabsRouter } from 'app/components/Tabs/Tabs.jsx'
import resources from 'app/store/resources'
import ModalEditTake from 'app/containers/Take/ModalEditTake/ModalEditTake.tsx'
import { openModal } from 'app/components/Modal'
import pipe from 'app/core/utils/pipe'
import type { Pipe } from 'app/core/utils/pipeNext.type'
import { Title } from 'app/components/Texts/Title/Title.jsx'
import { MUIButton } from 'app/components/Form'
import { getResources, getTaskTimeEstim, getTaskTimeReal } from 'app/store/selectors'
import { TableTakes } from 'app/containers/Take/TableTakes/TableTakes.jsx'
import Infos from 'app/containers/Assets/AssetDetail/Infos/Infos.tsx'
import { router as assetRouter } from 'app/containers/Assets/AssetDetail/router.js'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import { getColorFromBackground } from 'app/libs/helpers/getColorFromBackground'
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL'
import { getLastTakeFromTask } from 'app/pages/Project/ProductionManagement/FollowUp/utils'
import type { Task, ID, Asset, User } from 'app/core/types'
import { ModalEditTask } from './ModalEditTask.jsx'
import { Route, Link, router } from './router'
import { TaskHistory } from './TaskHistory.jsx'
import { TaskInformations } from './TaskInformations.jsx'
import { permission, Permission } from '../Permissions'
import classes from './Task.module.scss'
import { ModalAddActivity } from './ModalAddActivity.jsx'

type Props = {|
  task: Task,
  timeEstim: string,
  timeReal: string,
  project: ?Asset,
  asset?: Asset,
  user: User,
|}

type State = {
  paginatedList: any,
}

class TaskView extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      paginatedList: '',
    }
  }

  setPaginatedList = (paginatedList) => {
    this.setState({ paginatedList })
  }

  getTabs = () => {
    const { task, asset } = this.props
    const activeItems = [
      { key: 'information', name: 'information', params: { taskId: task.id }, label: 'Informations' },
      { key: 'takes', name: 'takes', params: { taskId: task.id }, label: 'Takes' },
      {
        projectId: task?.assetInst?.project,
        permission: ['projet_tasks__history'],
        key: 'history',
        name: 'history',
        params: { taskId: task.id },
        label: 'History',
      },
    ]

    if (asset) {
      activeItems.splice(1, 0, {
        key: 'assetInformation',
        name: 'assetInformation',
        params: { assetId: task.assetInst.id },
        label: 'Asset Informations',
      })
    }

    return activeItems
  }

  addActivity = () => {
    const { task, user } = this.props

    openModal(<ModalAddActivity task={task.id} project={task.assetInst.project} userId={user.asset} />)
  }

  render(): React$Node {
    const { task, timeEstim, timeReal, project, asset } = this.props
    const lastTake = getLastTakeFromTask(task)

    if (!task)
      return (
        <div className="fullWidth fullHeight flex center alignCenter bold grey">
          <div className={classes.removedLabel}>Removed</div>
        </div>
      )

    return (
      <div className="flex column fullWidth fullHeight">
        <div className="flex row noWrap fullWidth">
          <div className="flex column fullWidth">
            <div className="flex row alignCenter">
              <Title size="1">{task.name}</Title>
              <div className="marginLeftAuto">
                <div className="flex row wrap">
                  <div className={classes.user}>{task.assignedUserInst && task.assignedUserInst.name}</div>
                  {lastTake && task.statusInst && lastTake.statusInst.color ? (
                    <div
                      className={`${classes.status} marginRight20`}
                      style={{
                        backgroundColor: lastTake.statusInst.color,
                        color: getColorFromBackground(lastTake.statusInst.color),
                      }}
                    >
                      {lastTake.statusInst.name}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="paddingBottom10">
              <div className="flex row alignCenter fullWidth spaceBetween">
                {asset && asset.thumbnailInst && asset.thumbnailInst.url ? (
                  <div
                    className={classes.thumbnail}
                    style={{
                      background: `url(${asset.thumbnailInst.url}) center center / cover no-repeat`,
                    }}
                  />
                ) : null}
                <div
                  onClick={
                    asset
                      ? () => {
                          assetRouter.goTo('index', { assetId: asset.id }, { rightPanel: true })
                        }
                      : undefined
                  }
                  className={`${classes.assetLink} flex row noWrap alignCenter`}
                >
                  {task.assetInst.name} <FontIcon icon="fas-external-link-square-alt" className="marginLeft10" />
                </div>
                <Permission actions={['projet_tasks__can add activity on all tasks']}>
                  <MUIButton onClick={this.addActivity}>Add activity</MUIButton>
                </Permission>
              </div>
            </div>
          </div>
          <div>
            <Permission actions={['projet_tasks_tasks_update']}>
              <MUIButton
                icon="fas-edit"
                color="primary"
                onClick={() =>
                  openModal(<ModalEditTask taskId={task.id} title={`Edit task ${task.name}`} canDelete={true} />)
                }
              />
            </Permission>
          </div>
        </div>
        <div className="flex row noWrap fullWidth fullHeight">
          <div className="flex column fullWidth">
            <TabsRouter tabs={this.getTabs()} className={classes.tabs} localRouter={{ Route, Link, router }}>
              <Route name="information">
                <TaskInformations
                  project={project}
                  asset={asset}
                  task={task}
                  timeEstim={timeEstim}
                  timeReal={timeReal}
                  lastTake={lastTake}
                />
              </Route>
              {asset ? <Route name="assetInformation" component={Infos} componentProps={{ asset }} /> : null}
              <Route permission={['projet_tasks__history']} name="history" projectId={task?.assetInst?.project}>
                <TaskHistory assetId={task.id} projectId={task?.assetInst?.project} tableId="taskHistory" />
              </Route>
              <Route name="takes">
                <TableTakes
                  taskId={task.id}
                  setPaginatedList={this.setPaginatedList}
                  paginatedList={this.state.paginatedList}
                  project={task.assetInst.project}
                  toggleButtons={
                    permission(['projet_tasks_takes_create'])
                      ? [
                          {
                            onClick: () => {
                              return openModal(
                                <ModalEditTake paginatedList={this.state.paginatedList} taskId={task.id} />,
                              )
                            },
                            label: 'Create a take',
                            key: 'createTake',
                          },
                        ]
                      : undefined
                  }
                />
              </Route>
            </TabsRouter>
          </div>
        </div>
      </div>
    )
  }
}

const pipeInst: Pipe<{ params: { taskId: ID } }, typeof TaskView> = pipe()

const TaskContainer: React$ComponentType<any> = pipeInst
  .withRouter()
  .connect((state, props) => {
    const { taskId } = props.params
    const task = getResources(state, 'tasks', taskId, [
      'assignedUserInst',
      'statusInst',
      'assetInst',
      'assetInst.flagsInst',
      'assetInst.thumbnailInst',
      'takesInst',
      'takesInst.statusInst',
    ])

    return {
      task,
      asset: task ? getResources(state, 'assets', task.asset) : undefined,
      timeEstim: task && getTaskTimeEstim(state, taskId),
      timeReal: task && getTaskTimeReal(state, taskId),
      project: task ? getResources(state, 'assets', task.assetInst.project, ['thumbnailInst']) : null,
      user: state.user,
    }
  })
  .request(async (props) => {
    return resources.tasks
      .fetchOne(props.params.taskId)
      .then((res) => {
        const taskProject = res && res.resources && res.resources[0] && res.resources[0].assetInst.project
        const projectId = getProjectIdFromURL() || taskProject
        const requestsConfig = { params: { headers: { [window.OVM_PROJECT_HEADER]: projectId || '' } } }

        return resources.takes.fetchAllByTask(props.params.taskId, requestsConfig)
      })
      .catch((err) => console.error(err))
  })
  .render(({ params, history, location, match, ...props }) => <TaskView {...props} />)

export default function (): React$Element<any> {
  return <Route name="task" component={TaskContainer} />
}
