var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'app/core/api/api.js';
const initialState = {
    loading: false,
    data: [],
    count: 0,
    error: null,
};
export const fetchAllProjects = createAsyncThunk('projects/fetchAll', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { fulfillWithValue, rejectWithValue }) {
    try {
        const res = yield api.projects.fetchAll({ page_size: 1000 });
        return fulfillWithValue(res);
    }
    catch (err) {
        return rejectWithValue(err);
    }
}));
const { reducer } = createSlice({
    name: 'projects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // FETCH ALL PROJECTS
        builder.addCase(fetchAllProjects.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAllProjects.fulfilled, (state, { payload }) => {
            state.data = payload.results;
            state.loading = false;
        });
        builder.addCase(fetchAllProjects.rejected, (state, { payload }) => {
            state.error = payload;
            state.loading = false;
        });
    },
});
export default reducer;
