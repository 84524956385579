import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const useModalResize = () => {
    var _a;
    const user = useSelector((state) => state.user);
    const defaultSettings = {
        height: 650,
        width: 700,
        textHeight: 200,
    };
    const userSettings = localStorage.getItem(`userSettings-${user === null || user === void 0 ? void 0 : user.asset}`);
    const localStorageModalSettings = ((_a = JSON.parse(userSettings || '')) === null || _a === void 0 ? void 0 : _a.modalEditSize) || defaultSettings;
    const [modalSettings, setModalSettings] = useState(localStorageModalSettings);
    useEffect(() => {
        handleLocalStorage(modalSettings);
    }, [modalSettings]);
    const handleLocalStorage = (settings) => {
        const localStorageUserSettings = localStorage.getItem(`userSettings-${user === null || user === void 0 ? void 0 : user.asset}`);
        if (localStorageUserSettings) {
            const localStorageData = JSON.parse(localStorageUserSettings);
            const newOptions = Object.assign(Object.assign({}, localStorageData), { modalEditSize: Object.assign({}, settings) });
            localStorage.setItem(`userSettings-${user === null || user === void 0 ? void 0 : user.asset}`, JSON.stringify(newOptions));
        }
        else {
            localStorage.setItem(`userSettings-${user === null || user === void 0 ? void 0 : user.asset}`, JSON.stringify({ modalEditSize: Object.assign({}, settings) }));
        }
    };
    return {
        modalSettings,
        setModalSettings,
    };
};
export default useModalResize;
