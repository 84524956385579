var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { map } from 'lodash';
import resources from 'app/store/resources';
import { optionsProgressionStatus, progressionStatusFilter } from 'app/core/utils/optionsProgressionStatus';
import { ModalConfirmForm } from 'app/components/Modal';
import { FormData, FlagsRelations } from 'app/components/Form';
import { getResources } from 'app/store/selectors';
import { getLastTakeFromTask } from 'app/pages/Project/ProductionManagement/FollowUp/utils';
import { useSelector } from 'react-redux';
import useModalResize from 'app/hooks/useModalResize';
const ModalEditTake = (props) => {
    const { take, taskId, onSave, multipleCellsEdit, paginatedList, onChange } = props, rest = __rest(props, ["take", "taskId", "onSave", "multipleCellsEdit", "paginatedList", "onChange"]);
    const { modalSettings, setModalSettings } = useModalResize();
    const lastTake = useSelector((state) => {
        return getLastTakeFromTask(getResources(state, 'tasks', taskId, ['takesInst']));
    });
    const task = useSelector((state) => {
        return getResources(state, 'tasks', taskId);
    });
    const progressionStatus = useSelector((state) => {
        return getResources(state, 'progressionStatus');
    });
    const handleSave = (changeData, data, defaultData) => __awaiter(void 0, void 0, void 0, function* () {
        const { flags, closeStatus } = changeData, dataToSave = __rest(changeData, ["flags", "closeStatus"]);
        if (onSave)
            return onSave(changeData);
        const takeToSave = Object.assign({ id: take && take.id, name: 'take', task: task.id }, dataToSave);
        return resources.takes[take ? 'update' : 'create'](takeToSave, { paginatedList }).then((res) => {
            const takeId = (take && take.id) || res.resources[0].id;
            const flagsToSave = {
                id: takeId,
                takeFlagsInst: flags && map(flags, ({ flagInst }) => ({ take: takeId, flag: flagInst.id })),
            };
            return Promise.all([
                resources.takes.save(flagsToSave),
                lastTake && closeStatus ? resources.takes.update({ id: lastTake.id, status: closeStatus }) : null,
            ]).then((res) => {
                if (onChange)
                    onChange(res);
                return res;
            });
        });
    });
    return (_jsx(ModalConfirmForm, Object.assign({ title: take ? `Edit take of task ${task.name}` : 'Create new take', resizable: true, onResizeStop: (_, { size }) => setModalSettings((prevVal) => (Object.assign(Object.assign({}, prevVal), size))), minHeight: 550, minWidth: 420, width: modalSettings.width, height: modalSettings.height, multipleCellsEdit: multipleCellsEdit }, rest, { children: _jsx(FormData, { onSave: handleSave, defaultData: !take
                ? {
                    flags: [],
                }
                : Object.assign(Object.assign({}, take), { flags: take.takeFlagsInst }), properties: [
                ...(!take && lastTake
                    ? [
                        {
                            label: 'Close status of previous take',
                            key: 'closeStatus',
                            useSelected: true,
                            type: 'select',
                            elementProps: {
                                fullWidth: true,
                                options: optionsProgressionStatus(progressionStatusFilter(progressionStatus, 'with', ['close', 'retake'])),
                                isRequired: true,
                            },
                        },
                    ]
                    : []),
                {
                    label: `Status ${!take && lastTake ? ' of new take' : ''}`,
                    key: 'status',
                    type: 'select',
                    elementProps: {
                        options: optionsProgressionStatus(!take && lastTake
                            ? progressionStatusFilter(progressionStatus, 'without', ['close', 'retake'])
                            : progressionStatus),
                        isRequired: true,
                        fullWidth: true,
                    },
                },
                {
                    key: 'estimLength',
                    label: 'Duration estim',
                    type: 'duration',
                    elementProps: (data) => {
                        const isACanceledStatus = (progressionStatus[data.status] || { statusType: 0 }).statusType === 3;
                        return {
                            disabled: isACanceledStatus,
                        };
                    },
                },
                {
                    label: 'Brief',
                    key: 'comment',
                    type: 'richtext',
                    elementProps: {
                        onInit: (editor) => editor === null || editor === void 0 ? void 0 : editor.editing.view.focus(),
                        onResizeStop: (textHeight) => setModalSettings((prevVal) => (Object.assign(Object.assign({}, prevVal), { textHeight }))),
                        defaultHeight: modalSettings.textHeight,
                    },
                },
                {
                    key: 'flags',
                    label: 'Flags',
                    type: 'autocomplete',
                    element: FlagsRelations,
                    elementProps: {
                        foreignKey: 'take',
                        resourceID: take && take.id,
                        category: 'take',
                        itemCategory: 'take',
                        placeholder: 'Select a flag',
                    },
                },
            ].filter((_) => _) }) })));
};
export default ModalEditTake;
