/** @flow */
import React, { cloneElement } from 'react'
import mapValues from 'lodash/mapValues'
import { error, success } from 'app/components/Notifications/notify'
import { cyLabelFormater } from 'app/libs/helpers/cyTools'
import { ModalFormContext } from 'app/components/Modal/ModalConfirmForm.jsx'
import FontIcon from 'app/components/FontIcon/FontIcon.jsx'
import Tooltip from '@material-ui/core/Tooltip'
import classes from './FormData.module.scss'
import getDefaultField from './getDefaultField.tsx'
import propertyTypes from './propertyTypes'
import type { PropertyTypesProps } from './propertyTypes'
import Label from '../Label/Label.jsx'
import FormField from '../FormField/FormField.jsx'
import FormFieldRight from '../FormField/FormFieldRight.jsx'
import Validators from '../Validators/Validators.jsx'
import { MUIButton, Checkbox } from '..'

export { FormError } from './FormDataError'

export type FormDataProperty = {|
  label?: React$Node,
  key: string,
  type: PropertyTypesProps,
  element?: React$Component<any> | Object,
  elementProps?: ((data: Object, setData: Function) => Object) | Object,
  dontInjectProps?: boolean,
  isShow?: (data: Object) => boolean,
  tooltip?: React$Node,
  rank?: number,

  // TODO to verifie
  value?: any,
  useSelected?: boolean,
|}

export type FormaDataProps = {|
  showSaveBtn?: boolean,
  onSave: Function,
  defaultData?: Object,
  template?: (
    properties: { [key: string]: Object },
    list: Array<string>,
    genericComponents: { saveBtn: ?React$Element<any> },
    data: Object,
    setData: Function,
    selectableFields: { [key: string]: boolean } | null,
    setSelectableField: (key: string, value: boolean) => void,
  ) => React$Element<any>,
  saveBtn?: React$Element<any>,
  saveLabelBtn?: string,
  flashNotifSuccessLabel?: string,
  selectableFields?: boolean,
  flashNotifErrorLabel?: string,
  extraData?: Object,
  disableNotif?: boolean,
  properties?: ((data: Object) => Array<FormDataProperty>) | Array<FormDataProperty>,
  isSync?: bollean,
|}

type State = {|
  isSaving: boolean,
  data: Object,
  errors: Object,
  selectableFields: { [key: string]: boolean },
|}

export default class FormData extends React.PureComponent<FormaDataProps, State> {
  static defaultProps: $Shape<FormaDataProps> = {
    showSaveBtn: true,
    defaultData: {},
    extraData: {},
    onSave: () => Promise.resolve(),
    saveLabelBtn: undefined,
    saveBtn: <MUIButton>Save</MUIButton>,
    disableNotif: false,
    flashNotifSuccessLabel: 'Saved',
    flashNotifErrorLabel: 'Form error',
    isSync: false,
  }

  static types: * = propertyTypes

  constructor(props: FormaDataProps) {
    super(props)

    this.state = {
      isSaving: false,
      data: props.defaultData,
      errors: {},
      selectableFields: {},
    }
  }

  isMounted: boolean = true

  validatorsRef: Object = {}

  containerRef: React$ElementRef<any> = React.createRef()

  contextValue: {
    getApi: ({ submitForm: Function, onFocus: Function }) => void,
    multipleCellsEdit: boolean,
  } | void

  componentDidMount() {
    if (this.contextValue) {
      this.contextValue.getApi({
        submitForm: this.save,
        onFocus: () => {
          const $fieldToFocus = this.containerRef.current.querySelector(
            'input:not([data-do-not-focus-autocomplete="true"])',
          )
          if ($fieldToFocus) {
            setTimeout(() => $fieldToFocus.focus(), 100)
          }
        },
      })
    }
  }

  componentWillUnmount() {
    this.isMounted = false
  }

  setData: Function = (data: Object) => {
    if (this.isMounted === true)
      this.setState((state) => ({
        data: { ...state.data, ...data },
        errors: { ...state.errors, ...mapValues(data, (value, key) => []) },
      }))
  }

  getProperties: Function = () => {
    const { properties } = this.props
    const { data } = this.state

    if (typeof properties === 'function') return properties(data)
    return properties
  }

  template: Function = (
    properties: { [key: string]: Object },
    list: Array<string>,
    genericComponents: { saveBtn: ?React$Element<any> },
    data: Object,
    setData: Function,
    selectableFields: { [key: string]: boolean } | null,
    setSelectableField: (key: string, value: boolean) => void,
  ) => {
    const propertiesElements = list.map((propertyKey) => {
      const property = properties[propertyKey]
      const isCheckbox = property.type === 'checkbox' || property.key === 'choice'
      return (
        <div
          style={{
            display: 'flex',
            marginBottom: '17px',
            alignItems: isCheckbox && !property.element.props.data?.choice ? 'center' : 'initial',
            flexDirection: isCheckbox && !property.element.props.data?.choice ? 'row-reverse' : 'column',
            justifyContent: isCheckbox ? 'flex-end' : 'initial',
          }}
        >
          <Label style={{ marginBottom: isCheckbox && !property.element.props.data?.choice ? 0 : '5px' }}>
            {property.label}
          </Label>
          {property.tooltip ? (
            <div style={{ marginBottom: '5px', paddingLeft: '15px' }}>
              <Tooltip placement="bottom" arrow={true} title={property.tooltip}>
                <FontIcon icon="fas-question-circle" />
              </Tooltip>
            </div>
          ) : null}
          {selectableFields ? (
            <div className="flex center alignCenter" key="checkbox">
              <Checkbox
                size="small"
                checked={Boolean(selectableFields[propertyKey])}
                onChange={(checked) => setSelectableField(propertyKey, checked)}
              />
            </div>
          ) : null}
          <FormFieldRight
            style={
              isCheckbox && !property.element.props.data?.choice
                ? { display: 'initial', width: 'initial', marginRight: '8px' }
                : null
            }
            key="right"
            cy={cyLabelFormater('form', property.label)}
            disabled={selectableFields ? Boolean(!selectableFields[propertyKey]) : undefined}
            {...property.formFieldProps}
          >
            {property.element}
          </FormFieldRight>
        </div>
      )
    })

    const { saveBtn } = genericComponents

    return (
      <div style={{ padding: '0 15px 15px' }}>
        <FormField selectable={Boolean(selectableFields)}>{propertiesElements}</FormField>
        {saveBtn ? (
          <div
            style={{
              display: 'table',
              marginRight: 0,
              position: 'relative',
              marginLeft: 'auto',
              marginTop: 20,
            }}
          >
            {saveBtn}
          </div>
        ) : null}
      </div>
    )
  }

  setSelectableField: Function = (key: string, value: boolean) => {
    this.setState((state) => ({ selectableFields: { ...state.selectableFields, [key]: value } }))
  }

  getParsedData: Function = () => {
    const { selectableFields } = this.props
    const { selectableFields: selectableFieldsState, data: stateData } = this.state
    const { multipleCellsEdit = false } = this.contextValue || {}

    const properties = this.getProperties() || []

    const data = {}

    if (this.getProperties) {
      for (const property of properties) {
        if (selectableFields || multipleCellsEdit) {
          if (selectableFieldsState[property.key]) data[property.key] = stateData[property.key]
          continue
        }
        data[property.key] = stateData[property.key]
      }
    }

    return data
  }

  save: Function = async () => {
    const { onSave, flashNotifSuccessLabel, flashNotifErrorLabel, disableNotif, defaultData } = this.props
    const { data: stateData } = this.state

    this.setState({ isSaving: true, errors: {} })

    const refsToValid = Object.values(this.validatorsRef).filter((ref) => ref)

    const isValid = await Validators.validAll(refsToValid)

    if (!isValid) {
      this.setState({ isSaving: false })
      if (!disableNotif && flashNotifErrorLabel) error(flashNotifErrorLabel)
      return Promise.reject(new Error('Form is not valid')).catch((err) => err)
    }

    const data = this.getParsedData()

    if (this.props.isSync) {
      onSave(data, stateData, defaultData)
      success(flashNotifSuccessLabel)
      return
    }

    const promise = onSave(data, stateData, defaultData)

    if (!promise || !promise.then) {
      console.error(`FormData onSave() must return a Promise`)
      return Promise.reject(new Error(`FormData onSave() must return a Promise`)).catch((err) => err)
    }

    return promise
      .then((res) => {
        if (this.isMounted === true) this.setState({ isSaving: false })
        if (res && !disableNotif && flashNotifSuccessLabel) success(flashNotifSuccessLabel)
        return res
      })
      .catch((err) => {
        if (this.isMounted === true) {
          this.setState((state) => ({
            errors: {
              [err.propertyKey]: [err.propertyMessage],
            },
          }))
        }
        if (err?.serverError && err?.serverError.status === 409) {
          error('Resource already exist !')
        } else {
          error(flashNotifErrorLabel)
        }
        throw err
      })
  }

  renderFieldType: Function = (property: {
    key: string,
    type: PropertyTypesProps,
    element: Object | Function,
    elementProps: Function | Object,
    dontInjectProps?: boolean,
  }) => {
    const { data, errors } = this.state
    const { extraData } = this.props
    let elementProps

    if (property.elementProps) {
      const type = typeof property.elementProps
      if (type === 'object') {
        ;({ elementProps } = property)
      } else if (type === 'function') {
        elementProps = property.elementProps(data, this.setData.bind(this), extraData)
      } else {
        return console.error(`ModalForm: elementProps type ${type} is not allowed`)
      }
    }

    let AppField

    if (property.type === propertyTypes.custom) {
      AppField = property.element(data, this.setData.bind(this), extraData, {
        validatorRef: (c) => {
          this.validatorsRef[property.key] = c
        },
        errors: errors[property.key],
        ...elementProps,
      })
    } else {
      AppField = getDefaultField(property, data, this.setData.bind(this))
    }

    if (property.dontInjectProps) return AppField

    // $FlowFixMe
    return cloneElement(AppField, {
      validatorRef: (c) => {
        this.validatorsRef[property.key] = c
      },
      errors: errors[property.key],
      ...elementProps,
    })
  }

  renderTemplate: Function = () => {
    const { data, selectableFields: stateSelectableFields } = this.state
    const { template = this.template, selectableFields } = this.props
    const { multipleCellsEdit = false } = this.contextValue || {}

    const properties = this.getProperties() || []
    const propertiesObj = {}
    const propertiesList = []

    for (const property of properties) {
      if (!property || (property.isShow && !property.isShow(this.state.data))) continue

      propertiesList.push(property.key)

      propertiesObj[property.key] = {
        ...property,
        element: this.renderFieldType(property),
      }
    }

    const genericComponents = {
      saveBtn: this.renderOnSave(),
    }

    return template(
      propertiesObj,
      propertiesList,
      genericComponents,
      data,
      this.setData,
      selectableFields || multipleCellsEdit ? stateSelectableFields : null,
      this.setSelectableField,
    )
  }

  renderOnSave: Function = (): ?React$Element<any> => {
    const { showSaveBtn, saveBtn = <MUIButton>Save</MUIButton>, saveLabelBtn } = this.props

    if (showSaveBtn === false || this.contextValue) return null

    return cloneElement(saveBtn, {
      onClick: this.save,
      loader: this.state.isSaving,
      children: saveLabelBtn || 'Save',
    })
  }

  render(): React$Node {
    return (
      <ModalFormContext.Consumer>
        {(contextValue) => {
          this.contextValue = contextValue

          return (
            <div className={classes.container} ref={this.containerRef}>
              {this.renderTemplate()}
            </div>
          )
        }}
      </ModalFormContext.Consumer>
    )
  }
}
