import { jsx as _jsx } from "react/jsx-runtime";
import { optionsDynamicApprovalStatus } from 'app/core/utils/optionsDynamicApprovalStatus';
import { ModalConfirmForm } from 'app/components/Modal';
import { FormData } from 'app/components/Form';
import { useSelector } from 'react-redux';
import useModalResize from 'app/hooks/useModalResize';
const ModalEditDynamicApproval = (props) => {
    const { dynamicApprovalValue, multipleCellsEdit, exponentTitle, onChange, onRequestClose } = props;
    const { modalSettings, setModalSettings } = useModalResize();
    const { asset: authorId } = useSelector((state) => state.user);
    const defaultData = !dynamicApprovalValue
        ? {}
        : {
            status: dynamicApprovalValue.status,
            comment: dynamicApprovalValue.comment,
        };
    const onSave = (data) => {
        const dynamicApprovalValueData = {
            status: dynamicApprovalValue.status !== data.status ? data.status : undefined,
            comment: dynamicApprovalValue.comment !== data.comment ? data.comment : undefined,
            author: dynamicApprovalValue.author !== authorId ? authorId : undefined,
        };
        return onChange(dynamicApprovalValueData);
    };
    return (_jsx(ModalConfirmForm, { title: "Edit Dynamic Approval", resizable: true, onResizeStop: (_, { size }) => setModalSettings((prevVal) => (Object.assign(Object.assign({}, prevVal), size))), minHeight: 450, minWidth: 420, width: modalSettings.width, height: modalSettings.height, onRequestClose: onRequestClose, exponentTitle: exponentTitle, children: _jsx(FormData, { onSave: onSave, defaultData: defaultData, selectableFields: multipleCellsEdit, properties: [
                {
                    label: 'Status',
                    key: 'status',
                    type: 'select',
                    elementProps: {
                        isRequired: true,
                        options: optionsDynamicApprovalStatus,
                        fullWidth: true,
                        placeholder: 'Select a status',
                    },
                },
                {
                    label: 'Comment',
                    key: 'comment',
                    type: 'richtext',
                    elementProps: {
                        onInit: (editor) => editor === null || editor === void 0 ? void 0 : editor.editing.view.focus(),
                        onResizeStop: (textHeight) => setModalSettings((prevVal) => (Object.assign(Object.assign({}, prevVal), { textHeight }))),
                        defaultHeight: modalSettings.textHeight,
                    },
                },
            ] }) }));
};
export default ModalEditDynamicApproval;
