import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';
import FontIcon from 'app/components/FontIcon/FontIcon.jsx';
import classes from './ProjectCard.module.scss';
const ProjectCard = ({ project }) => {
    var _a, _b, _c;
    return (_jsx("li", { className: classes.item, "data-testid": `projectCard-${project.name}`, children: _jsxs(Link, { to: `/projects/${project.id}`, children: [_jsx("div", { className: classes.imgTop, children: project.thumbnailInst ? (_jsx("div", { className: classes.img, style: { backgroundImage: `url(${project.thumbnailInst.url})` } })) : (_jsx(FontIcon, { icon: "fas-image", className: classes.iconImage })) }), _jsxs("div", { className: classes.bottomContent, children: [_jsxs("div", { className: classes.title, children: [_jsx("div", { className: classes.itemName, children: startCase(project === null || project === void 0 ? void 0 : project.name) }), ((_a = project === null || project === void 0 ? void 0 : project.attributes) === null || _a === void 0 ? void 0 : _a.trigram) ? (_jsx("div", { className: classes.trigram, children: project.attributes.trigram.toUpperCase() })) : null] }), ((_b = project === null || project === void 0 ? void 0 : project.attributes) === null || _b === void 0 ? void 0 : _b.season) ? _jsxs("div", { children: ["Season: ", project.attributes.season] }) : null, ((_c = project === null || project === void 0 ? void 0 : project.attributes) === null || _c === void 0 ? void 0 : _c.nbEpisodes) ? _jsxs("div", { children: ["Nb of Episodes: ", project.attributes.nbEpisodes] }) : null] })] }) }, project.id));
};
export default ProjectCard;
