import api from 'app/core/api/api.js'
import { actionSetProperties } from 'app/libs/reduxSetProperty'

export const types = { ADD: 'ADD', SET_PROPERTY: 'SET_PROPERTY' }

export const setProperties = actionSetProperties(types.SET_PROPERTY)

export const fetchUser = () => (dispatch) => {
  return api.user().then(async (user) =>
    api.users.projects({ id: user.asset }).then(({ results }) => {
      const localUser = {
        ...user,
        projects: results,
      }
      return dispatch(setProperties(localUser))
    }),
  )
}
