var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// $FlowFixMe
import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import cx from 'classnames';
import { MUIModal } from 'app/components/Modal';
import { validExtensions } from 'app/components/Medias/MediaItem/fileExtensions.js';
import { permission } from 'app/containers/Permissions';
import { MediasList } from 'app/components/Table/Cells/CellMedias/MediasList.jsx';
import { MUIButton } from 'app/components/Form';
import classes from './ModalMedias.module.scss';
import useModalResize from 'app/hooks/useModalResize';
export function ModalMedias(props) {
    const { onCancel, title, medias, accept = validExtensions.join(', '), readOnly = !permission(['uploads___upload']), maxSize = 50 * 1000000, // 50Mo
    onValidate: _onValidate, multipleSelection, onRequestClose, exponentTitle, allowPinMedia, allowValidateMedia, } = props;
    const [errors, setErrors] = useState([]);
    const { modalSettings, setModalSettings } = useModalResize();
    const [files, setFiles] = useState([]);
    const [toDelete, setToDelete] = useState([]);
    function onDeleteMedia(media) {
        if (media.id) {
            setToDelete([...toDelete, media.id]);
        }
        else {
            setFiles(files.filter((file) => file.url !== media.url));
        }
    }
    const { getRootProps, getInputProps, fileRejections, isDragReject } = useDropzone({
        accept,
        maxSize,
        noDrag: readOnly,
        onDrop: (acceptedFiles = []) => {
            acceptedFiles.forEach((file) => {
                file.url = URL.createObjectURL(file);
            });
            const newFiles = files.concat(acceptedFiles);
            setFiles(newFiles);
        },
    });
    useEffect(() => {
        setErrors((fileRejections || []).map(({ file }) => {
            const message = `${file.path} - ${file.size > maxSize ? 'The file size is too large.' : 'Unsuported format'}`;
            return message;
        }));
        setTimeout(() => {
            var _a;
            if (setErrors && ((_a = (fileRejections || [])) === null || _a === void 0 ? void 0 : _a.length) > 0)
                setErrors([]);
        }, 7000);
    }, [fileRejections]);
    const _a = getRootProps(), { onClick } = _a, rootProps = __rest(_a, ["onClick"]);
    let allMedias = files;
    if (medias && !multipleSelection) {
        allMedias = allMedias.concat(medias.filter((media) => !toDelete.includes(media.id)));
    }
    function onValidate() {
        return __awaiter(this, void 0, void 0, function* () {
            return _onValidate({ toCreate: files, toDelete });
        });
    }
    return (_jsx(MUIModal, { title: title || 'Medias', onCancel: onCancel, onResizeStop: (_, { size }) => setModalSettings((prevVal) => (Object.assign(Object.assign({}, prevVal), size))), height: modalSettings.height, width: modalSettings.width, resizable: true, draggable: true, onValidate: onValidate, onRequestClose: onRequestClose, exponentTitle: exponentTitle, extendsButtons: !readOnly ? (_jsx(MUIButton, { icon: "fas-plus", onClick: onClick, children: "Add a media" })) : undefined, children: _jsxs("div", Object.assign({}, rootProps, { className: cx(classes.dropzone, isDragReject && classes.dropzoneError), children: [_jsx("input", Object.assign({}, getInputProps(), { "data-testid": "uploadMedias" })), allMedias.length === 0 ? (_jsx("div", { className: "fullWidth fullHeight flex center alignCenter lightgrey", children: "Add a media or drag and drop a file here." })) : null, _jsx(MediasList, { medias: allMedias, onDelete: !readOnly ? onDeleteMedia : undefined, allowPinMedia: allowPinMedia, allowValidateMedia: allowValidateMedia }), errors.length > 0 ? (_jsx("div", { className: classes.errors, children: errors.map((err, index) => (_jsx("div", { className: "flex", children: _jsx("div", { className: classes.error, children: err }) }, err))) })) : null] })) }));
}
