// @flow
import React from 'react'
import { Choice, Checkbox } from 'app/components/Form'
import type { ViewableType } from 'app/core/types'

import classes from './AttributesEditor.module.scss'

type ChoiceTypeInputProps = {|
  setData: Function,
  data: *,
  type: ViewableType,
|}

export function ChoiceTypeInput(props: ChoiceTypeInputProps): React$Node {
  const { data, setData, type } = props
  const defaultChoice: Array<any> = props.data?.choice || []
  const checked = !!data.choice

  const onChange: (value: Array<any>) => void = (value) => {
    if (setData && typeof setData === 'function') {
      setData({ ...data, choice: value })
    }
  }

  const onCheck: (checked: boolean) => void = (checked) => {
    if (data.choice) setData({ ...data, choice: undefined })
    else setData({ ...data, choice: defaultChoice })
  }

  return (
    <div className={classes.choiceTypeInput_container}>
      <div style={{ marginRight: checked ? '8px' : 0 }} className={classes.choiceTypeInput_checkbox}>
        <Checkbox checked={checked} onChange={onCheck} />
      </div>
      {checked ? <Choice type={type} data={data.choice} onChange={onChange} /> : null}
    </div>
  )
}
