var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { deleteLeave } from 'app/store/reducers/leaves';
import { TableRedux } from 'app/components/Table/TableRedux';
import { map, uniq } from 'lodash';
import { confirmDelete } from 'app/components/Modal';
import { leavesByUserAndDateRangeSelector, leavesByUserAndDateSelector, leavesDataSelector, } from 'app/store/selectors/leaves';
import getColumns from './columns';
const TableTimeRecapLeaves = (props) => {
    const { date, userId, projects, formatView, selectedWeek } = props;
    const dispatch = useDispatch();
    const tableId = 'table-time-recap-leaves';
    const userProjects = useSelector((state) => state.user.projects);
    const { fetchLoading, count } = useSelector(leavesDataSelector);
    const userLeaves = useSelector(selectedWeek && formatView
        ? leavesByUserAndDateRangeSelector(userId, moment(selectedWeek).startOf(formatView).format('YYYY-MM-DD'), moment(selectedWeek).endOf(formatView).format('YYYY-MM-DD'))
        : leavesByUserAndDateSelector(userId, date));
    const deleteLeavesAction = (instance, cell) => {
        const { selectedCells } = instance.getLastestSelection();
        const toDeleteActivities = map(selectedCells, (cell) => cell.row.original.id);
        const nb = toDeleteActivities.length;
        return {
            label: `Delete ${nb > 1 ? `${nb} ` : ''}leave${nb > 1 ? 's' : ''}`,
            editAction: true,
            onClick: () => {
                const toDelete = uniq(map(selectedCells, (cell) => cell.row.original.id));
                return confirmDelete({
                    render: `Are you sur you want to delete ${toDelete.length > 1 ? 'these' : 'this'} leave${toDelete.length > 1 ? 's' : ''} ?`,
                    onValidate: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield dispatch(deleteLeave(toDelete.length > 1 ? toDelete : toDelete[0]));
                    }),
                    validateMessage: `Leave${toDelete.length > 1 ? 's' : ''} deleted`,
                });
            },
        };
    };
    const columns = useMemo(() => getColumns({ userProjects, formatView, deleteLeavesAction, dispatch }), [userProjects]);
    return _jsx(TableRedux, { tableId: tableId, data: userLeaves, columns: columns, loading: fetchLoading, count: count });
};
export default TableTimeRecapLeaves;
