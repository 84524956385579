// @flow
import React from 'react'
import cx from 'classnames'
import type { ElementProps } from 'app/core/types'
import classes from './FormField.module.scss'

type Props = {|
  ...ElementProps,
  className?: string,
  children: any,
  cy: string,
  disabled?: boolean,
  style?: {},
|}

export default class FormFieldRight extends React.PureComponent<Props> {
  render(): React$Node {
    const { className, cy, disabled, style, ...props } = this.props

    return (
      <div
        data-testid={cy}
        className={cx(classes.right, className)}
        style={{ ...style, pointerEvents: disabled ? 'none' : 'all' }}
        {...props}
      >
        {disabled ? <div className={classes.disabled} onClick={(e) => e.preventDefault()} /> : null}
        {this.props.children}
      </div>
    )
  }
}
