/** @flow */
import React from 'react'
import { map } from 'lodash'
import TextRead from 'app/components/TextEditor/TextRead.jsx'
import type { Note, Asset } from 'app/core/types'
import { openModal } from 'app/components/Modal/index.js'
import type { Cell, CellInstance } from 'app/components/Table/types.js'

import { ExpansionCell } from './ExpansionCell.jsx'
import classes from './CellNotesRead.module.scss'
import { CellRichTextModal } from '../CellRichText/CellRichTextModal.tsx'

type ReadProps = {|
  episode: { [episodeName: string]: Array<Note> },
  cell: Cell,
  asset: Asset,
  instance: CellInstance,
|}

export function CellNotesRead(props: ReadProps): React$Node {
  const { episode, asset, cell, instance } = props

  const { prefs, updateCells } = instance
  const { getCellProps, state, setState: _setState } = cell
  const { isRowExpanded, toggleExpandRow, edition } = getCellProps()
  const { maxLineHeight } = prefs
  const { endEdit, save } = edition

  const setState = (key, value) => {
    cell.update?.()
    _setState({
      ...state,
      [key]: value,
    })
  }
  const onClick = (event: SyntheticMouseEvent<any>) => {
    event.nativeEvent.stopImmediatePropagation()
    event.preventDefault()
  }

  const onDoubleClick = (note: Note, episodeName: string) => (event: SyntheticMouseEvent<any>) => {
    return openModal(
      <CellRichTextModal
        value={note.text || ''}
        allowEmpty={true}
        modalTitle={`${asset.name} - ${episodeName}`}
        onChange={(text: string) => {
          save({ ...note, text }, { waitEndOfPromise: true }).then((res) => {
            updateCells({ [cell.id]: cell })
            cell.update?.()
          })
        }}
        onRequestClose={endEdit}
      />,
    )
  }

  return (
    <div className={classes.container} style={{ maxHeight: maxLineHeight }}>
      {map(episode, (notes, episodeName: string) => (
        <ExpansionCell
          headerStyle={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
          key={episodeName}
          title={episodeName}
          open={state[episodeName]}
          setOpen={(e, value) => {
            if (value && !isRowExpanded) toggleExpandRow(e)
            setState(episodeName, value)
          }}
        >
          <>
            {map(notes, (note) => {
              const title = new DOMParser().parseFromString(note.text || '', 'text/html').documentElement
              return (
                <ExpansionCell
                  key={note.id}
                  headerStyle={{
                    color: 'grey',
                    fontSize: 12,
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  title={title ? title.textContent : ''}
                  open={state[note.id]}
                  setOpen={(e, value) => {
                    setState(note.id, value)
                  }}
                >
                  <div
                    tabIndex="0"
                    className={classes.textCell}
                    onClick={onClick}
                    onDoubleClick={onDoubleClick(note, episodeName)}
                  >
                    <TextRead text={note.text || ''} />
                  </div>
                </ExpansionCell>
              )
            })}
          </>
        </ExpansionCell>
      ))}
    </div>
  )
}
