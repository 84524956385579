// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-components-Form-FormField-___FormField-module__formField {
  display: flex;
  flex-direction: column;
}

.app-components-Form-FormField-___FormField-module__right {
  height: inherit;
  max-height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.app-components-Form-FormField-___FormField-module__disabled {
  position: absolute;
  top: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  pointer-events: none;
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./app/components/Form/FormField/FormField.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,UAAU;EACV,oCAAoC;EACpC,kBAAkB;EAClB,oBAAoB;EACpB,WAAW;EACX,YAAY;AACd","sourcesContent":[".formField {\n  display: flex;\n  flex-direction: column;\n}\n\n.right {\n  height: inherit;\n  max-height: 100%;\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  width: 100%;\n}\n\n.disabled {\n  position: absolute;\n  top: 0;\n  z-index: 5;\n  background-color: rgba(0, 0, 0, 0.1);\n  border-radius: 3px;\n  pointer-events: none;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formField": `app-components-Form-FormField-___FormField-module__formField`,
	"right": `app-components-Form-FormField-___FormField-module__right`,
	"disabled": `app-components-Form-FormField-___FormField-module__disabled`
};
export default ___CSS_LOADER_EXPORT___;
