/** @flow */
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import resources from 'app/store/resources'
import { getResources } from 'app/store/selectors/getResources'
import type { TrackingSchema, ID, ResourcesList, Step, DynamicApproval, Attribute } from 'app/core/types'
import history from 'app/main/routerHistory'
import { assetIcons } from 'app/components/Icons/assetsIcons'
import { assetsTypes } from 'app/core/constants/assetsTypes'
import FontIcon from 'app/components/FontIcon/FontIcon'
import { Tooltip } from 'app/components/Tooltip/Tooltip'
import TableConstructor from 'app/components/TableConstructor/TableConstructor.jsx'
import { ColorPickerPopper } from 'app/components/Form/ColorPicker/ColorPickerPopper.jsx'

import { confirmDelete } from 'app/components/Modal/confirmDelete.jsx'
import { openModal } from 'app/components/Modal/openModal'

import type { NodeConstructor } from 'app/components/TreeDnD/Node'
import { uuid } from 'app/libs/uuid'
import type { SchemaColumn } from 'app/core/types/Schema'
import type { TrackingSchemaItem } from 'app/core/types/TrackingSchema'
import {
  faA,
  faCheckSquare,
  faCube,
  faLink,
  faP,
  faSquareRootAlt,
  faStepForward,
  faStickyNote,
} from '@fortawesome/free-solid-svg-icons'
import { schemaToTrackingSchema, trackingSchemaToSchema } from './utils'
import classes from './TrackingSchemas.module.scss'
import { ModalEditTrackingSchemaColumn } from './ModalEditTrackingSchemaColumn.jsx'
import { ModalEditTrackingSchemaGroup } from './ModalEditTrackingSchemaGroup.jsx'
import { ModalEditTrackingSchemaTable } from './ModalEditTrackingSchemaTable.jsx'
import type { TrackingSchemaColumnSettings } from './utils'
import { userPrefLocalResource } from 'app/core/utils/localUserPrefs.js'

type TrackingSchemasProps = {|
  projectId: ?ID,
  trackingSchemaId: ID,
|}

export function TrackingSchemas(props: TrackingSchemasProps): React$Node {
  const { trackingSchemaId, projectId } = props

  const [updateTimestamp, setUpdateTimestamp] = useState<number>(0)

  const { trackingSchema, steps, dynamicApprovals, attributes } = useSelector((state) => ({
    trackingSchema: getResources<TrackingSchema>(state, 'trackingSchemas', trackingSchemaId),
    steps: getResources<ResourcesList<Step>>(state, 'steps'),
    dynamicApprovals: getResources<ResourcesList<DynamicApproval>>(state, 'dynamicApprovals'),
    attributes: getResources<ResourcesList<Attribute>>(state, 'attributes'),
  }))

  const sessionCurrentItemId = userPrefLocalResource.getData('currentTreeEntry', undefined, true)

  function trackingSchemaUpdater(path: string[], value: Object) {
    if (!path.length) return { ...trackingSchema, ...value }

    const newTrackingSchema = { ...trackingSchema }

    if (path.length === 1) {
      const groupIndex = newTrackingSchema.schema.findIndex((group) => group.key === path[0])
      Object.assign(newTrackingSchema.schema[groupIndex], value)
    }

    if (path.length === 2) {
      const groupIndex = newTrackingSchema.schema.findIndex((group) => group.key === path[0])
      const columnIndex = newTrackingSchema.schema[groupIndex].items.findIndex((group) => group.key === path[1])
      Object.assign(newTrackingSchema.schema[groupIndex].items[columnIndex], value)
    }
    return newTrackingSchema
  }

  async function update(trackingSchema: TrackingSchema) {
    setUpdateTimestamp(Date.now())
    const res = await resources.trackingSchemas.update(trackingSchema)
    return res
  }

  async function changeColor(path: string[], color: string) {
    const res = await update(trackingSchemaUpdater(path, { color }))
    return res
  }

  async function editColumn(path: string[]) {
    const groupIndex = trackingSchema.schema.findIndex((group) => group.key === path[0])
    const columnIndex = trackingSchema.schema[groupIndex].items.findIndex((group) => group.key === path[1])
    const column = trackingSchema.schema[groupIndex].items[columnIndex]

    if (!column) return

    openModal(
      <ModalEditTrackingSchemaColumn
        column={column}
        onSave={async (data) => {
          const schema = [...trackingSchema.schema]
          const item = {
            ...column,
            columnName: data.columnName,
            description: data.description,
            fixable: data.fixable,
            fixed: data.fixed,
            hiddenable: data.hiddenable,
            isVisible: data.isVisible,
            hidden: data.hidden,
            readOnly: data.readOnly,
            editorType: data.editorType,
            editorParams: data.editorParams,
            onEditEditorType: data.onEditEditorType,
          }

          schema[groupIndex].items.splice(columnIndex, 1, item)

          const res = await update({ ...trackingSchema, schema })
          return res
        }}
      />,
    )
  }

  function deleteColumn(path: string[]) {
    confirmDelete({
      render: 'Are you sure you want to delete this column?',
      onValidate: async () => {
        const schema = [...trackingSchema.schema]
        const groupIndex = schema.findIndex((group) => group.key === path[0])
        const columnIndex = schema[groupIndex].items.findIndex((group) => group.key === path[1])
        schema[groupIndex].items.splice(columnIndex, 1)
        const res = await update({ ...trackingSchema, schema })
        return res
      },
      validateMessage: 'Column deleted',
    })
  }

  function addGroup() {
    openModal(
      <ModalEditTrackingSchemaGroup
        onSave={async (data) => {
          const newTrackingSchema = { ...trackingSchema }
          newTrackingSchema.schema.unshift({
            groupName: data.groupName,
            color: undefined,
            type: 'group',
            key: uuid(),
            readOnly: data.readOnly === true,
            cellParams: {},
            items: [],
          })

          const res = await update(newTrackingSchema)
          return res
        }}
      />,
    )
  }

  function editGroup(path: string[]) {
    const groupIndex = trackingSchema.schema.findIndex((group) => group.key === path[0])
    const group = trackingSchema.schema[groupIndex]

    if (!group) return

    const readOnlyItems = group.items.map((item) => Boolean(item.readOnly))
    const readOnly = readOnlyItems.reduce((acc, val) => {
      if (acc === 'ind') return acc
      if (acc !== null && acc !== val) return 'ind'
      return val
    }, false)

    openModal(
      <ModalEditTrackingSchemaGroup
        group={group}
        readOnly={readOnly}
        onSave={async (data) => {
          if (data.readOnly !== 'ind') {
            group.items.forEach((item) => {
              item.readOnly = data.readOnly !== 'ind' ? data.readOnly : false
            })
          }

          const newTrackingSchema = { ...trackingSchema }
          newTrackingSchema.schema[groupIndex] = { ...group, groupName: data.groupName }

          const res = await update(newTrackingSchema)
          return res
        }}
      />,
    )
  }

  function deleteGroup(path: string[]) {
    confirmDelete({
      render: 'Are you sure you want to delete this group?',
      onValidate: async () => {
        const schema = [...trackingSchema.schema]
        const groupIndex = schema.findIndex((group) => group.key === path[0])
        schema.splice(groupIndex, 1)
        const res = await update({ ...trackingSchema, schema })
        return res
      },
      validateMessage: 'Group deleted',
    })
  }

  function editTable() {
    if (!projectId) return
    openModal(<ModalEditTrackingSchemaTable projectId={projectId} trackingSchema={trackingSchema} />)
  }

  function deleteTable() {
    if (!trackingSchema) return
    confirmDelete({
      render: `Are you sure you want to delete ${trackingSchema.name} ?`,
      onValidate: async () => {
        const res = await resources.trackingSchemas.delete(trackingSchema.id)
        history.push(`/projects${projectId ? `/${projectId}/settings/follow-up` : ''}`)
        return res
      },
      validateMessage: 'Table deleted',
    })
  }

  function getNodeColumnName(item) {
    const { settings, data, name } = item

    const stepsItem: Step = steps[data.id]
    const dynamicApprovalsItem: DynamicApproval = dynamicApprovals[data.id]
    const attributesItem: Attribute = attributes[data.id]

    let column = item

    if (stepsItem) column = { ...item, data: { ...item.data, ...stepsItem } }
    if (dynamicApprovalsItem) column = { ...item, data: { ...item.data, ...dynamicApprovalsItem } }
    if (attributesItem) column = { ...item, data: { ...item.data, ...attributesItem } }

    let subLabel
    if ((settings.columnType && settings.columnType !== column.name) || settings.name !== column.name) {
      subLabel = <span className={classes.itemSubLabel}>{settings.columnType || settings.name}</span>
    }

    if (data.targetStep) {
      const step = steps[data.targetStep]
      const leafItems = trackingSchema.schema.reduce(
        (acc, tsGroup) => (tsGroup.items ? [...acc, ...tsGroup.items] : acc),
        [],
      )
      const leafSteps = leafItems.filter((i) => i.type === 'steps')
      const targetColumn = leafSteps.find((item) => data.id === step.id)

      subLabel = (
        <>
          {subLabel}
          <span className={`${classes.itemSubLabel} grey`}>
            <FontIcon icon="fas-arrow-right" className="marginRight5" />
            {targetColumn ? targetColumn.columnName : step?.name}
          </span>
        </>
      )
    }

    let editorLabel

    if (settings.onEditEditorType && data.editorType)
      editorLabel = (
        <span className={classes.itemEditorLabel}>
          <FontIcon icon="fas-arrow-right" className="marginRight5" />
          {`${data.editorType} (${Object.entries(data.editorParams).join(' ')})`}
        </span>
      )

    return (
      <span className={classes.label}>
        {name}
        {editorLabel}
        {subLabel}
      </span>
    )
  }

  function getNodeIcon(type, parentType) {
    switch (true) {
      case [type, parentType].includes('assets'):
        return faCube
      case [type, parentType].includes('attributes'):
        return faA
      case [type, parentType].includes('parentAttributes'):
        return faP
      case [type, parentType].includes('postBoardNotes'):
        return faStickyNote
      case [type, parentType].includes('assetLinks'):
        return faLink
      case [type, parentType].includes('lastTake'):
        return faStepForward
      case [type, parentType].includes('tasks'):
        return faStepForward
      case [type, parentType].includes('steps'):
        return faStepForward
      case [type, parentType].includes('dynamicApprovals'):
        return faCheckSquare
      case [type, parentType].includes('calculatedField'):
        return faSquareRootAlt
      default:
        return null
    }
  }

  function setNode<Data>(item, path): $Shape<NodeConstructor<Data>> {
    if (!item.items) {
      const { settings } = item
      const { readOnly, color, type, parentType } = settings

      return {
        name: getNodeColumnName(item),
        faIcon: getNodeIcon(type, parentType),
        actions: [
          readOnly
            ? {
                key: 'readOnly',
                'data-testid': 'lock',
                label: <FontIcon icon="fas-lock" />,
                tooltip: 'This column is in "Read Only" mode.',
                onClick: () => {},
              }
            : null,
          {
            key: 'color',
            'data-testid': 'color',
            label: <ColorPickerPopper color={color} onChange={(color) => changeColor(path, color)} />,
            onClick: () => {},
            tooltip: 'Change color',
          },
          {
            key: 'menu',
            'data-testid': 'menu',
            onClick: () => editColumn(path),
            label: <FontIcon icon="fas-cog" />,
            tooltip: 'Column settings',
          },
          {
            key: 'delete',
            'data-testid': 'delete',
            label: <FontIcon icon="fas-trash" />,
            onClick: () => deleteColumn(path),
            tooltip: 'Remove column',
          },
        ].filter(Boolean),
      }
    }

    const { settings } = item
    const { color } = settings

    return {
      actions: [
        {
          key: 'color',
          'data-testid': 'color',
          onClick: () => {},
          label: <ColorPickerPopper color={color} onChange={(color) => changeColor(path, color)} />,
          tooltip: 'Change color',
        },
        {
          key: 'edit',
          'data-testid': 'edit',
          onClick: () => editGroup(path),
          label: <FontIcon icon="fas-cog" />,
          tooltip: 'Group settings',
        },
        {
          key: 'delete',
          'data-testid': 'delete',
          label: <FontIcon icon="fas-trash" />,
          onClick: () => deleteGroup(path),
          tooltip: 'Remove group',
        },
      ],
    }
  }

  const schema = useMemo(() => trackingSchemaToSchema(trackingSchema), [trackingSchema, updateTimestamp])

  function onSchemaChange(schema) {
    return resources.trackingSchemas.update(schemaToTrackingSchema(schema, trackingSchema))
  }

  const header = (
    <div className={classes.tableNameContainer}>
      <div className={classes.tableTitle} data-testid="followup-table-name">
        <FontIcon icon="fas-table" style={{ color: 'rgb(178, 185, 199)', fontSize: '1.2em', marginRight: 10 }} />
        {trackingSchema.name}
        <div className="flex row noWrap marginLeft10">
          <Tooltip title="Add a folder">
            <div className={classes.viewTableButton} onClick={addGroup}>
              <FontIcon icon="fas-folder-plus" />
            </div>
          </Tooltip>
          <Tooltip title="Edit table settings">
            <div className={classes.viewTableButton} onClick={editTable}>
              <FontIcon icon="fas-cog" />
            </div>
          </Tooltip>
          <Tooltip title="Delete the table">
            <div className={classes.viewTableButton} onClick={deleteTable}>
              <FontIcon icon="fas-trash" />
            </div>
          </Tooltip>

          {projectId && (
            <Tooltip title="Go to follow-ups">
              <div
                className={classes.viewTableButton}
                onClick={() => {
                  history.push(
                    sessionCurrentItemId
                      ? `/projects/${projectId}/follow-up/${sessionCurrentItemId}/${trackingSchemaId}`
                      : `/projects/${projectId}/follow-up`,
                  )
                }}
              >
                <FontIcon icon="fas-external-link-alt" />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      {trackingSchema.isExternal ? (
        <div className={`${classes.tableType} flex grey`}>
          <Tooltip title="This table is accessible by external users from the External Follow-Up tab.">
            <div className={`${classes.tableTypeLabel} flex row alignCenter`}>
              <FontIcon icon="fas-external-link-square-alt" className="marginRight5" />
              <span data-testid="followup-external">External table</span>
            </div>
          </Tooltip>
        </div>
      ) : null}
      <div className={`${classes.tableType} flex`}>
        <Tooltip title="Asset type of table">
          <div className={`${classes.tableTypeLabel} flex row alignCenter`}>
            <FontIcon icon={assetIcons(trackingSchema.assetType)} className="marginRight5" />
            <span data-testid="followup-assettype">{assetsTypes[trackingSchema.assetType]}</span>
          </div>
        </Tooltip>
      </div>
    </div>
  )

  function onAddExternalNode(data, group): SchemaColumn<TrackingSchemaItem, TrackingSchemaColumnSettings> {
    const {
      key,
      name,
      type,
      timestamp,
      color,
      columnName,
      description,
      fixed,
      fixable,
      hiddenable,
      isVisible,
      hidden,
      readOnly,
      cellParams,
      columnType,
      aggregatedName,
      aggregated,
      parentName,
      contentType,
      ...item
    } = data

    ;(item: TrackingSchemaItem)

    const originalName =
      aggregatedName || `${aggregated ? `aggregated ` : ''}${name}${parentName ? ` (${parentName})` : ''}`

    const schemaColumn: SchemaColumn<TrackingSchemaItem, TrackingSchemaColumnSettings> = {
      key: uuid(),
      name: originalName,
      settings: {
        name: originalName,
        columnName: originalName,
        timestamp: Date.now().toString(),
        description: '',
        hiddenable: true,
        cellParams,
        columnType,
        type,
        color,
        fixable,
        isVisible,
        hidden,
        readOnly,
        contentType,
      },
      data: { ...item, name },
    }

    return schemaColumn
  }

  return (
    <div style={{ height: 'calc(100% - 50px)', overflowY: 'auto', position: 'relative' }}>
      {header}
      <div className="paddingLeft30">
        <TableConstructor
          style={{ height: 'calc(100% - 55px)' }}
          schema={schema}
          onSchemaChange={onSchemaChange}
          setNode={setNode}
          onAddExternalNode={onAddExternalNode}
        />
      </div>
    </div>
  )
}
