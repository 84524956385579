var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import moment from 'moment';
import { FormData } from 'app/components/Form';
import { ModalConfirmForm } from 'app/components/Modal';
import classes from './ModalAddAbsenceActivity.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { createLeave, updateLeave } from 'app/store/reducers/leaves';
export function ModalAddAbsenceActivity(props) {
    const { paginatedList, userId, preSelectedDate = new Date(), dayPart, duration, onChange, showDuration, preSelectedCells, period, tableTime, dayLeave } = props, rest = __rest(props, ["paginatedList", "userId", "preSelectedDate", "dayPart", "duration", "onChange", "showDuration", "preSelectedCells", "period", "tableTime", "dayLeave"]);
    const dispatch = useDispatch();
    // const userProjects = useMemo(() => getResources<Asset[]>(undefined, 'assets', store.getState().user.projects), [])
    const userProjects = useSelector((state) => state.user.projects);
    const { data: leaves } = useSelector((state) => state.leaves);
    function onAddCustomActivity(data) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            // Date formatting for one day leave
            const startDate = moment
                .utc(data.date)
                .set('hour', [dayPart, data.durationText].includes('afternoon') ? 12 : 8)
                .set('minute', 0)
                .set('second', 0)
                .format();
            const endDate = moment
                .utc(data.date)
                .set('hour', [dayPart, data.durationText].includes('morning') ? 12 : 18)
                .set('minute', 0)
                .set('second', 0)
                .format();
            // Date formatting for period leave
            const periodStartDate = moment
                .utc(data.startDate)
                .set('hour', data.startDateAfternoon ? 12 : 8)
                .set('minute', 0)
                .set('second', 0)
                .format();
            const periodEndDate = moment
                .utc(data.endDate)
                .set('hour', data.endDateMorning ? 12 : 18)
                .set('minute', 0)
                .set('second', 0)
                .format();
            const leave = leaves.find((leave) => leave.id === (dayLeave === null || dayLeave === void 0 ? void 0 : dayLeave.id));
            const newData = {
                id: leave === null || leave === void 0 ? void 0 : leave.id,
                user: userId,
                startDate: period ? periodStartDate : startDate,
                endDate: period ? periodEndDate : endDate,
                approvalStatus: 0,
                project: data.project ? data.project.value : undefined,
                comment: (_a = data.comment) !== null && _a !== void 0 ? _a : '',
            };
            const postRes = yield dispatch((leave === null || leave === void 0 ? void 0 : leave.id) ? updateLeave(newData) : createLeave(newData));
            // onChange?.(postRes.resources)
            return postRes;
        });
    }
    let preSelectedCellMinDate = new Date();
    let preSelectedCellMaxDate = new Date();
    if (preSelectedCells && !tableTime) {
        preSelectedCellMinDate = moment(Object.keys(preSelectedCells)[0], 'YYYY-MM-DD').toDate();
        preSelectedCellMaxDate = moment(Object.keys(preSelectedCells)[Object.keys(preSelectedCells).length - 1], 'YYYY-MM-DD').toDate();
    }
    if (preSelectedCells && tableTime) {
        preSelectedCellMinDate = moment(Object.keys(preSelectedCells)[0], 'YYYY-MM-DD').toDate();
        preSelectedCellMaxDate = moment(Object.keys(preSelectedCells)[Object.keys(preSelectedCells).length - 1], 'YYYY-MM-DD').toDate();
    }
    return (_jsxs(ModalConfirmForm, Object.assign({ title: "Add a leave", minWidth: 500 }, rest, { children: [_jsxs("div", { className: classes.leavesReminder, children: [_jsx("h4", { children: "Rappel" }), _jsx("p", { children: "La demande de cong\u00E9s doit se faire au minimum 15 jours avant, et 1 mois pour les cong\u00E9s d'\u00E9t\u00E9." })] }), _jsx(FormData, { onSave: onAddCustomActivity, flashNotifSuccessLabel: "Leave added", defaultData: {
                    date: moment(preSelectedDate).format('YYYY-MM-DD'),
                    startDate: moment(preSelectedCellMinDate).format('YYYY-MM-DD'),
                    endDate: moment(preSelectedCellMaxDate).format('YYYY-MM-DD'),
                }, properties: (preSelectedCells && Object.keys(preSelectedCells).length < 2) || (!preSelectedCells && !period)
                    ? [
                        {
                            key: 'project',
                            label: 'Project',
                            type: 'autocomplete',
                            elementProps: () => ({
                                options: () => [
                                    ...userProjects.map((project) => ({ label: project.name, value: project.id })),
                                    { label: '-', value: null },
                                ].sort((a, b) => a.label.localeCompare(b.label)),
                                style: { width: '100%' },
                            }),
                        },
                        showDuration
                            ? {
                                key: 'durationText',
                                label: 'Duration',
                                type: 'select',
                                elementProps: {
                                    options: [
                                        { value: 'morning', label: 'Morning' },
                                        { value: 'afternoon', label: 'Afternoon' },
                                        { value: '', label: 'All day' },
                                    ],
                                    style: { width: '100%' },
                                },
                            }
                            : null,
                        {
                            key: 'date',
                            label: 'Date',
                            type: 'string',
                            elementProps: (data, setData) => ({
                                type: 'date',
                                isRequired: true,
                                value: data.date ? moment(data.date).format('YYYY-MM-DD') : undefined,
                                min: '2000-01-01',
                                onChange: (e) => setData({ date: e.target.value }),
                            }),
                        },
                        {
                            key: 'comment',
                            label: 'Comment',
                            type: 'textarea',
                            elementProps: (data, setData) => {
                                var _a;
                                return ({
                                    style: { width: '100%' },
                                    type: 'string',
                                    value: (_a = data.comment) !== null && _a !== void 0 ? _a : undefined,
                                });
                            },
                        },
                    ].filter(Boolean)
                    : [
                        {
                            key: 'project',
                            label: 'Project',
                            type: 'autocomplete',
                            elementProps: (data, setData) => ({
                                options: () => [{ label: '-', value: null }].concat(userProjects.map((project) => ({ label: project.name, value: project.id }))),
                                style: { width: '100%' },
                            }),
                        },
                        {
                            key: 'startDate',
                            label: 'Start Date',
                            type: 'string',
                            elementProps: (data, setData) => ({
                                type: 'date',
                                isRequired: true,
                                value: data.startDate ? moment(data.startDate).format('YYYY-MM-DD') : undefined,
                                min: '2000-01-01',
                                onChange: (e) => setData({ startDate: e.target.value }),
                            }),
                        },
                        {
                            key: 'startDateAfternoon',
                            label: 'Only afternoon',
                            type: 'checkbox',
                        },
                        {
                            key: 'endDate',
                            label: 'End Date',
                            type: 'string',
                            elementProps: (data, setData) => ({
                                type: 'date',
                                isRequired: true,
                                value: data.endDate ? moment(data.endDate).format('YYYY-MM-DD') : undefined,
                                min: '2000-01-01',
                                onChange: (e) => setData({ endDate: e.target.value }),
                            }),
                        },
                        {
                            key: 'endDateMorning',
                            label: 'Only morning',
                            type: 'checkbox',
                        },
                        {
                            key: 'comment',
                            label: 'Comment',
                            type: 'textarea',
                            elementProps: (data, setData) => {
                                var _a;
                                return ({
                                    style: { width: '100%' },
                                    type: 'string',
                                    value: (_a = data.comment) !== null && _a !== void 0 ? _a : undefined,
                                });
                            },
                        },
                    ].filter(Boolean) })] })));
}
