var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useEffect, useState } from 'react';
import { map } from 'lodash';
import { getResources } from 'app/store/selectors/getResources';
import resources from 'app/store/resources';
import { ExpansionPanelButton } from 'app/components/ExpansionPanel/ExpansionPanelButton.jsx';
import { copyTextToClipboard } from 'app/libs/copyTextToClipboard';
import ExpansionPanel from 'app/components/ExpansionPanel/ExpansionPanel.jsx';
import { permission } from 'app/containers/Permissions';
import { UsageShots } from './UsageShots';
import { ObjectValues } from 'app/libs/flowPolyfills';
import classes from './Usage.module.scss';
import { useSelector } from 'react-redux';
const Usage = ({ asset }) => {
    const episodes = useSelector((state) => {
        const data = getResources(state, 'assets.requests.fetchEpisodeUsage');
        return map(data).sort((a, b) => `${a.name}`.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));
    });
    const [allShotsByEpisode, setAllShotsByEpisode] = useState({});
    useEffect(() => {
        ;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            yield Promise.all([
                resources.assets.fetchEpisodeUsage(asset.id, {
                    params: { queries: { page_size: 1000 }, headers: { [window.OVM_PROJECT_HEADER]: asset.project || '' } },
                }),
            ]);
        }))();
    }, []);
    const fetchShotsUsage = (episode) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield resources.assets.fetchShotUsage({ assetId: asset.id, episodeId: episode.id }, {
            params: { queries: { page_size: 1000 }, headers: { [window.OVM_PROJECT_HEADER]: asset.project || '' } },
        });
        const { assets } = res.includedResources;
        const assetLinks = res.resources;
        let shotsUsage = {};
        assetLinks.forEach((assetLink) => {
            const shot = shotsUsage[assetLink.from_asset];
            if (shot) {
                shotsUsage[assetLink.from_asset].count += 1;
            }
            else {
                shotsUsage[assetLink.from_asset] = Object.assign(Object.assign({}, assets[assetLink.from_asset]), { count: 1 });
            }
        });
        shotsUsage = ObjectValues(shotsUsage);
        shotsUsage.sort((a, b) => `${a.name}`.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }));
        const shotsText = map(shotsUsage, (usage) => ({
            label: `${usage.name}${usage.count === 1 ? '' : ` (x${usage.count})`}`,
            shot: usage,
        }));
        setAllShotsByEpisode((prevState) => {
            return Object.assign(Object.assign({}, prevState), { [episode.name]: shotsText });
        });
        return shotsText;
    });
    const getShotStrToCopy = (episode) => __awaiter(void 0, void 0, void 0, function* () {
        let shots;
        if (!allShotsByEpisode[episode.name]) {
            shots = yield fetchShotsUsage(episode);
        }
        else {
            shots = allShotsByEpisode[episode.name];
        }
        return shots.map((usage) => usage.label).join('\n');
    });
    const onCopyEpisodeToClipboard = (episode) => __awaiter(void 0, void 0, void 0, function* () {
        const shotsString = yield getShotStrToCopy(episode);
        copyTextToClipboard(shotsString);
    });
    return (_jsx("div", { className: classes.container, children: _jsx("div", { className: "fullWidth overflowYAuto", style: { height: 'calc(100% - 30px)' }, children: _jsx("div", { className: "padding5", children: map(episodes, (episode) => {
                    const iconsList = [
                        _jsx(ExpansionPanelButton, { icon: "fas-copy", onClick: () => onCopyEpisodeToClipboard(episode), tooltip: "copy episode's shots name in clipboard" }, "copy"),
                    ];
                    permission(['projet_breakdown__read']) &&
                        episode.project &&
                        iconsList.push(_jsx(ExpansionPanelButton, { icon: "fas-external-link-alt", to: `/projects/${episode.project}/breakdown/${episode.id}`, tooltip: "Redirect to episode breakdown" }, episode.id));
                    return (_jsx(ExpansionPanel, { rightContent: iconsList, title: episode.name, children: _jsx(UsageShots, { fetchShotsUsage: fetchShotsUsage, shots: allShotsByEpisode[episode.name], episode: episode }) }, episode.id));
                }) }) }) }));
};
export default Usage;
