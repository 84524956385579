var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
// $FlowFixMe
import { useRef, useState } from 'react';
import { ModalConfirmForm } from '../../../Modal';
import { FormData } from '../../../Form';
import useModalResize from 'app/hooks/useModalResize';
export function CellRichTextModal(props) {
    const { value = '', modalTitle = 'Text', onChange, onRequestClose, allowEmpty = true, open, exponentTitle } = props;
    const { modalSettings, setModalSettings } = useModalResize();
    const originalValue = value;
    const content = useRef(value);
    const setContent = (value) => {
        content.current = value;
    };
    const [disableValidate, setDisableValidate] = useState(true);
    function onValidate() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!allowEmpty && !content.current)
                return Promise.reject();
            return onChange(content.current);
        });
    }
    function _onChange(editorValue) {
        setContent(editorValue);
        if (editorValue !== originalValue)
            setDisableValidate(false);
        else
            setDisableValidate(true);
    }
    return (_jsx(ModalConfirmForm, { title: modalTitle, resizable: true, onResizeStop: (_, { size }) => setModalSettings((prevVal) => (Object.assign(Object.assign({}, prevVal), size))), minHeight: 550, minWidth: 420, width: modalSettings.width, height: modalSettings.height, disableValidate: disableValidate, open: open, exponentTitle: exponentTitle, onRequestClose: onRequestClose, children: _jsx(FormData, { onSave: onValidate, defaultData: { value }, properties: [
                {
                    key: 'value',
                    type: 'richtext',
                    elementProps: {
                        onChange: _onChange,
                        onInit: (editor) => editor === null || editor === void 0 ? void 0 : editor.editing.view.focus(),
                        onResizeStop: (textHeight) => setModalSettings((prevVal) => (Object.assign(Object.assign({}, prevVal), { textHeight }))),
                        defaultHeight: modalSettings.textHeight,
                    },
                },
            ] }) }));
}
