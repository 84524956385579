/** @flow */
import store from 'app/store/index.ts'
import type { ID } from 'app/core/types'
import getProjectIdFromURL from 'app/core/utils/getProjectIdFromURL'

const SUPER_ADMINS = ['samuel.bageot', 'lucas.miller']

export const userIsSuperAdmin = (user: string): boolean => SUPER_ADMINS.indexOf(user) !== -1

export const userIsAdmin = (): boolean => {
  return store.getState().user.isAdmin
}

export const userPermissions = (projectId?: ?ID): Array<string> => {
  const { data: config } = store.getState().projectConfig
  const project = projectId || getProjectIdFromURL()

  if (!config) return []

  const permissions = (config.ACTIONS[project] || []).concat(config.ACTIONS.default || [])

  return permissions
}
